import React from 'react'
import error from '../images/Close.png'

export default function PageNotFount() {
    return (
        <div className="main-component-wraper after-invitation-success cta">
            <div className="main-component">
                <div className="home-row">
                    <div className="col">
                        <img src={error} alt="add" className='add' />
                        <h1>404</h1>
                        <p>Stránka sa nenašla</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
