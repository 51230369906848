import React from 'react'
import settingsIcon from '../../images/editIcon.svg'

export default function Page1({ navigate, motorcyclesData, motion, selectedMotorcycle, setselectedMotorcycle }) {
    return (
        <>
            <div className="select-motorcycle">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px' }}>
                    <h2>Výber motocyklu</h2><img onClick={() => { navigate("/upravit-ucet") }} src={settingsIcon} alt="settings" />
                </div>

                {motorcyclesData ? motorcyclesData.map((motorcycle, i) => {
                    return (
                        <motion.div
                            className={`${selectedMotorcycle === i ? "selected" : ""} table-row-wrap`}
                            onClick={() => { setselectedMotorcycle(i) }}
                        >
                            <div className="table-row">
                                <div className="id"><b>{i + 1}.</b></div>
                                <div className="model">{motorcycle.model}</div>
                                <div className="cubature">{motorcycle.cubature} cm3</div>
                            </div>
                        </motion.div>
                    )
                }) :
                    <>
                        <h3>Zatiaľ nemáte pridané žiadne motorky</h3>
                        <motion.button
                            className="button-primary button-sign-in-for-event"
                            onClick={() => { navigate("/upravit-ucet") }}
                            whileHover={{ scale: 1.07 }}
                            whileTap={{ scale: 0.95 }}
                        >Pridať motorky</motion.button>
                    </>

                }
            </div>

        </>
    )
}
