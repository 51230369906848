import React from 'react'

export default function LoadingButton({ textLoading, text, loading, onClick, className }) {
    return (
        <>
            <button
                disabled={loading}
                className={`button-primary ${className ? className : ""}`}
                onClick={onClick}
            >{!loading ? text : textLoading}

                {loading ? <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="path" cx="25" cy="25" r="20" fill="none" stroke-width="3"></circle>
                </svg> : ""}
            </button>
        </>
    )
}
