import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import errorIcon from '../images/error_icon-red.svg'
import { ValidateNewAccount, FirebaseValidation } from '../contexts/Validation'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router'
import { doc, getFirestore, setDoc } from '@firebase/firestore'
import { useToast } from '../contexts/ToastContext'
import { useLogin } from '../contexts/LoginContext'

export default function CreateAccount() {
    const navigate = useNavigate()
    const EmailRef = useRef()
    const PasswordRef = useRef()
    const RepeatPasswordRef = useRef()
    const [error, seterror] = useState()

    const { createAcount, currentUser } = useAuth()
    const { openLogin } = useLogin()
    const showToast = useToast()

    async function HandleSubmit(event) {
        event.preventDefault();

        const validation = ValidateNewAccount(EmailRef.current.value, PasswordRef.current.value, RepeatPasswordRef.current.value)
        var uid;

        if (!validation) {
            try {
                seterror("")
                const user = await createAcount(EmailRef.current.value, PasswordRef.current.value)
                uid = user.user.uid

            } catch (error) {
                seterror(FirebaseValidation(error.code))
            }

            const db = getFirestore()
            try {

                await setDoc(doc(db, "users", uid), {
                    "userId": uid,
                    "email": EmailRef.current.value,
                    "created": new Date(),
                    "role": "user",
                    "registered_for": [],
                    "motorcyclesData": [],
                    "status": "created",
                    "avatar": "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"
                })

                showToast("Účet úspešne vytvorený", "success")

                navigate("/")


            } catch (e) {

                return
            }

        } else {
            seterror(validation)
            return
        }
    }


    if (currentUser) {
        return (
            <div className="new-account">
                <h1>Už ste prihlásený</h1>
            </div>
        )
    }
    else {

        return (

            <div className="new-account">
                <div className="form-wraper">
                    <form className="new-account">
                        <h1>Nový účet</h1>
                        {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                        <input autoComplete='off' className="box-shadow form-input" placeholder="E-mail" type="email" id="email" ref={EmailRef} />

                        <div className="password">
                            <input autoComplete='off' className="box-shadow form-input" placeholder="Heslo" type="password" id="password" ref={PasswordRef} />
                            <input autoComplete='off' className="box-shadow form-input" placeholder="Zopakujte Heslo" type="password" id="repeat-password" ref={RepeatPasswordRef} />
                        </div>

                        <span className="zabudnute-heslo">Už máte účet? <div onClick={() => { openLogin() }} className="link-highlighted" >Prihláste sa</div></span>

                        <motion.button
                            className="button-primary button-login "
                            onClick={HandleSubmit}
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Vytvoriť účet
                        </motion.button>

                    </form>
                </div>
            </div>
        )
    }
}
