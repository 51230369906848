import React, { } from 'react'
import { motion } from 'framer-motion'
import closeIcon from "../images/cancel.svg"
import successIcon from "../images/Toast_successIcon.svg"
import errorIcon from '../images/Toast_errorIcon.svg'

export function Toast({ type, message, close }) {
    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: "0"
        },
        visible: {
            y: "0",
            opacity: "1",
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 300,
            }
        },
        exit: {
            opacity: 0,

        },
    }
    var toastStyle = {}

    if (type === "success") {
        toastStyle.icon = successIcon
        toastStyle.type = "success"
        toastStyle.style = "content success"
    }
    else if (type === "error") {
        toastStyle.icon = errorIcon
        toastStyle.type = "error"
        toastStyle.style = "content err"
    }



    return (
        <motion.div
            key={"toast"}
            className="toast"
            variants={dropIn}
            initial="hidden"
            animate="visible"
            exit="exit"
        >

            <div className={toastStyle.style}>
                <div className="content-wraper">
                    <img src={toastStyle.icon} className="error-type" alt="toast-icon" />
                    <span className="message">{message}</span>
                    <img src={closeIcon} className="close-icon" onClick={() => (close())} alt="close-icon" />
                </div>
            </div>
        </motion.div>

    )

};