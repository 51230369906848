import React from 'react'

export default function RegroupingChange({ rider, i }) {
    return (
        <>
            <div key={i} className="box-shadow table-row card ">
                <div>{rider.from}</div>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 5L13.59 6.41L18.17 11H2V13H18.17L13.58 17.59L15 19L22 12L15 5Z" fill="#323232" />
                </svg>
                <div>{rider.to}</div>
                <div>{rider.rider.riderName}</div>
            </div>


        </>
    )
}
