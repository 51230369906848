import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'

import { AnimatePresence, motion } from 'framer-motion'
import { useToast } from '../contexts/ToastContext'
import { useNavigate } from 'react-router'


export default function LoggedInToolbar({ logout }) {
  const { currentUser } = useAuth()
  const [toggleToolbar, settoggleToolbar] = useState(true)
  const showToast = useToast()
  const navigate = useNavigate()

  function handleLogout() {
    const x = logout()

    window.location.reload(false);

  }

  const toolbarAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      zIndex: 10,
      transition: {
        duration: 0.2
      },
    },
    exit: {
      zIndex: 10,
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.2,

      },
    }
  };

  function toggle() {
    toggleToolbar ? settoggleToolbar(false) : settoggleToolbar(true)
  }

  useEffect(() => {

    const personImg = new Image()
    personImg.src = "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"


  }, [])


  return (
    <>

      <motion.div
        className="logged-in-menu-item"
        onClick={toggle}
        variants={toolbarAnimate}
      >

        <div className="inline-login">
          <img alt="person" src={`${currentUser.data.avatar ? currentUser.data.avatar : "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"}`} />

        </div>
        <AnimatePresence>
          {!toggleToolbar &&
            <div className="toolbar-gap">
              <motion.div
                className="toolbar"
                initial="exit"
                animate={"enter"}
                exit={"exit"}
                variants={toolbarAnimate}
              >
                <img alt="person" className='avatar' src={`${currentUser.data.avatar ? currentUser.data.avatar : "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"}`} />

                <div className="name">{currentUser.data.firstname + " " + currentUser.data.lastname}</div>
                <div className="email">{currentUser.user.email}</div>

                <div className="separator"></div>

                <div className="items-wrap">

                  <div className="item">
                    <div onClick={() => (navigate("/upravit-ucet"))}>
                      <span className="text">Upraviť účet</span>
                    </div>
                  </div>

                  <div className="item">
                    <div onClick={handleLogout}>
                      <span className="text">Odhlásiť sa</span>
                    </div>
                  </div>



                </div>
              </motion.div></div>}
        </AnimatePresence>
      </motion.div>
    </>
  )
}

