import React from 'react'
import { motion } from 'framer-motion'
import {useNavigate} from 'react-router'

export default function HeaderActionButtons({openLogin, closeLogin}) {

    const navigate = useNavigate()

    return (
        <>

        <motion.button 
            className="button-default"
            onClick ={() =>(openLogin()) } 
            whileHover ={{scale:1.05}}
            whileTap ={{scale:0.95}}
        >
            Prihlásiť sa
        </motion.button>

        <motion.button 
            className="button-primary" 
            onClick ={() =>(navigate("/novy-ucet")) } 
            whileHover ={{scale:1.05}}
            whileTap ={{scale:0.95}}
        >
            Nový účet
        </motion.button>
        </>
                
    )
}
