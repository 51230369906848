import React, { useState } from 'react'
import { AnimateSharedLayout, motion } from 'framer-motion'
import Backdrop from './Backdrop'
import errorIcon from '../images/error_icon-red.svg'
import { useNavigate } from 'react-router'
import { useAuth } from '../contexts/AuthContext'
import Page1 from './sign_in_form/Page1'
import Page2 from './sign_in_form/Page2'
import Page3 from './sign_in_form/Page3'
import Page4 from './sign_in_form/Page4'
import ProgressBar from './sign_in_form/ProgressBar'
import { addDoc, collection, doc, getFirestore, updateDoc } from 'firebase/firestore'

export default function SignInForEventPopup({ handleClose, data }) {

    const navigate = useNavigate()

    const [error, seterror] = useState()
    const { currentUser } = useAuth()
    const [loading, setloading] = useState(false)

    const [selectedMotorcycle, setselectedMotorcycle] = useState(-1)
    const [page, setpage] = useState(1)
    const [ucast, setucast] = useState()
    const [lapTime, setlapTime] = useState("")
    const [paymentMethod, setpaymentMethod] = useState()
    const [understand, setunderstand] = useState(false)
    const [registrationId, setregistrationId] = useState()

    function addLapTime(x) {
        setlapTime(x.target.value)
    }

    const dropIn = {
        hidden: {

            opacity: "0"
        },
        visible: {
            y: "0",
            opacity: "1",
        },
        exit: {

            opacity: 0

        },
    }

    const swipe = {
        hidden: { x: 50, opacity: 0 },
        visible: { x: 0, opacity: 1 },
        exit: { x: -50, opacity: 0 },
    }

    function Validate(move) {

        seterror("")

        if (move === -1) {
            seterror("")
            setpage(page + move)
            return
        }

        if (page === 1) {
            if (selectedMotorcycle < 0) {
                seterror("Vyberte motocykel")
                return
            }

            setpage(page + move)
            return
        }

        if (page === 2) {
            if (!ucast) {
                seterror("Označte jednu z možností")
                return
            }

            if (ucast === "ano") {
                if (lapTime === "") {
                    seterror("Zadajte čas jedného kola na okruhu")
                    return
                }

                if (lapTime) {

                    const time = lapTime.split(':')
                    if (time.length < 1) {
                        seterror("Čas zadaný v zlom formáte (chýba \":\")")
                        return
                    } else {
                        const min = time[0]
                        const sec = time[1]
                        if (isNaN(min) || isNaN(sec)) {
                            seterror("Čas zadaný v zlom formáte")
                            return
                        }

                        if (min < 0 || min > 60 || sec < 0 || sec > 60) {
                            seterror("Čas zadaný v zlom formáte")
                            return
                        }
                    }


                }

            }
            setpage(page + move)
            return
        }

        if (page === 3) {
            if (!paymentMethod) {
                seterror("Vyberte spôsob platby")
                return
            }

            if (!understand) {
                seterror("Musíte súhlasiť s obchodnými podminkami a so spracovaním osobných údajov")
                return
            }

            setloading(true)

            SubmitData()
        }

        if (page === 4) {

            handleClose()
            window.location.reload(false);
        }
    }

    async function SubmitData() {

        const registered_for = currentUser.data.registered_for


        if (registered_for.includes(data.eventId)) {
            window.location.reload(false);
            return
        }

        const time = lapTime.split(':')
        var min = time[0]
        var sec = time[1]


        min = parseInt(min)
        sec = parseInt(sec)

        const registration = {
            rider: {
                riderId: currentUser.data.userId,
                email: currentUser.data.email,
                name: currentUser.data.firstname + " " + currentUser.data.lastname,
                phoneNumber: currentUser.data.tel,
            },
            eventId: data.eventId,
            category: "",
            motorcycle: {
                cubature: currentUser.data.motorcycles[selectedMotorcycle].cubature,
                model: currentUser.data.motorcycles[selectedMotorcycle].model,
            },
            haveForm: false,
            isPresent: false,
            notes: "",
            oneLapTime: min * 60 + sec + 0,
            paymentMethod: paymentMethod,
            paymentReceived: false,
            registrationDate: new Date().getTime() * 1000,
            startNumber: null,
            priceForRegistration: data.price,
            status: "active"
        }



        if (paymentMethod === "hotovost") {
            const db = getFirestore()
            const docRef = collection(db, `/events_grid/${data.eventId}/registrations/`)
            const userRef = doc(db, `/users/${currentUser.data.userId}/`)



            addDoc(docRef, registration).then((snap) => {


                if (currentUser.data.registered_for) {
                    !currentUser.data.registered_for.includes(data.eventId) && currentUser.data.registered_for.push(data.eventId)
                }
                else {
                    currentUser.data.registered_for = [data.eventId]
                }

                updateDoc(userRef, { "registered_for": currentUser.data.registered_for })
                setregistrationId(snap.id)

                setloading(false)
                setpage(4)
                return
            }).catch((err) => {

            })


            setpage(4)
            setloading(false)

        } else {

            navigate("/checkout", { state: { registration, eventName: data.title, eventAmount: data.price } })
        }
    }

    return (

        <Backdrop>

            <motion.div
                onClick={(e) => { e.stopPropagation() }}
                className="modal card sign-in-for-event"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ ease: "easeIn", duration: 0.2 }}
            >
                <div className='content'>

                    <div className='first-row'>
                        <h1>Prihláška na {data.title}</h1>
                        <svg onClick={handleClose} xmlns="http://www.w3.org/2000/svg" className='close-icon' viewBox="0 0 48 48">
                            <g id="cancel" transform="translate(0 -0.001)">
                                <g id="Group_104" data-name="Group 104" transform="translate(0 0.001)">
                                    <path id="Path_28" data-name="Path 28" d="M26.652,24l20.8-20.8A1.875,1.875,0,0,0,44.8.55L24,21.349,3.2.55A1.875,1.875,0,0,0,.549,3.2L21.348,24,.549,44.8A1.875,1.875,0,0,0,3.2,47.452L24,26.653l20.8,20.8A1.875,1.875,0,1,0,47.451,44.8Z" transform="translate(0 -0.001)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </div>


                    <div className="event-date">{data.date}</div>

                    <ProgressBar page={page} motion={motion}></ProgressBar>

                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                    <div className="multistep-content">

                        <AnimateSharedLayout >
                            {page === 1 &&
                                <motion.div

                                    variants={swipe}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    transition={{ duration: 0.2 }}
                                >
                                    <Page1 motion={motion} navigate={navigate} motorcyclesData={currentUser.data.motorcycles} selectedMotorcycle={selectedMotorcycle} setselectedMotorcycle={setselectedMotorcycle}></Page1>
                                </motion.div>
                            }

                            {page === 2 &&
                                <motion.div

                                    variants={swipe}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    transition={{ duration: 0.2 }}
                                >
                                    <Page2 ucast={ucast} setucast={setucast} motion={motion} setlapTime={addLapTime} lapTime={lapTime}></Page2>
                                </motion.div>
                            }

                            {page === 3 &&
                                <motion.div

                                    variants={swipe}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    transition={{ duration: 0.2 }}
                                >
                                    <Page3 price={data.price} setpaymentMethod={setpaymentMethod} paymentMethod={paymentMethod} understand={understand} setunderstand={setunderstand}></Page3>
                                </motion.div>
                            }

                            {page === 4 &&
                                <motion.div

                                    variants={swipe}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                    transition={{ duration: 0.2 }}
                                >
                                    <Page4 registrationId={registrationId} eventId={data.eventId} eventName={data.title} email={currentUser.data.email}></Page4>
                                </motion.div>
                            }

                        </AnimateSharedLayout>
                    </div>
                </div>


                <div className='buttons'>
                    {page > 1 ?
                        <motion.button
                            className="button-default button-sign-in-for-event"
                            onClick={() => { Validate(-1) }}
                            whileHover={{ scale: 1.07 }}
                            whileTap={{ scale: 0.95 }}
                        >Späť</motion.button> : <div />}

                    <motion.button
                        disabled={loading}
                        className="button-primary button-sign-in-for-event"
                        onClick={() => { Validate(+1) }}
                        whileHover={{ scale: 1.07 }}
                        whileTap={{ scale: 0.95 }}
                    > {page < 3 && "Ďalej"} {page === 3 && "Zaplatiť"} {page === 4 && "Dokončiť"}</motion.button>
                </div>

            </motion.div>
        </Backdrop>
    )
}
