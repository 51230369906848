import React, { useContext, useEffect, useState } from 'react'
import { getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { initializeApp } from '@firebase/app';
import { doc, getDoc, getFirestore, onSnapshot, setDoc } from '@firebase/firestore';

const MyContext = React.createContext()


async function login(email, password) {

    const auth = getAuth()
    const ret = await signInWithEmailAndPassword(auth, email, password)
    return ret
}

export function logout() {
    const auth = getAuth()
    signOut(auth).then(() => {
        return "success"
    }).catch((err) => {


        return err
    })

}

export function createAcount(email, password) {
    initializeApp({
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APPID,
    })

    const auth = getAuth()
    return createUserWithEmailAndPassword(auth, email, password)

}

async function signInGoogle() {

    const auth = getAuth()
    const db = getFirestore()
    const provider = new GoogleAuthProvider()


    const result = await signInWithRedirect(auth, provider)

    const docRef = doc(db, "users", result.user.uid)
    const snap = await getDoc(docRef)

    if (!snap.exists()) {
        setDoc(docRef, {
            "userId": result.user.uid,
            "name": result.user.displayName,
            "email": result.user.email,
            "created": new Date(),
            "role": "user",
            "registered_for": [],
            "motorcyclesData": [],
            "status": "created",

        })
        window.location.reload(false)

        return new Promise((resolve, reject) => {
            resolve(result)
        })
    } else {
        return new Promise((resolve, reject) => {
            resolve("Vytvoril som používateľa")
        })
    }
}

export function AuthProvider(props) {
    const [loading, setloading] = useState(true)
    const [currentUser, setcurrentUser] = useState()
    useEffect(() => {
        initializeApp({
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
            projectId: process.env.REACT_APP_PROJECT_ID,
            storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
            messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
            appId: process.env.REACT_APP_APPID,
        })

        getAuth().onAuthStateChanged(user => {

            if (user) {
                const db = getFirestore()

                const docRef = doc(db, "users", user.uid)
                onSnapshot(docRef, (data) => {

                    setcurrentUser({ user, "data": data.data() })
                    setloading(false)
                })
            }
            else {
                setloading(false)
            }

        })
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)

    }, [])

    const value = {
        signInGoogle,
        currentUser,
        login,
        logout,
        createAcount,
        setcurrentUser
    }

    return (

        <MyContext.Provider value={value}>
            {!loading && props.children}
        </MyContext.Provider>
    )
}

export function useAuth() {
    return useContext(MyContext)
}