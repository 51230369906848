import { AnimatePresence } from 'framer-motion'
import React from 'react'


export default function Page2({ ucast, setucast, motion, lapTime, setlapTime }) {


    return (
        <div className='circuit-data'>

            <h3>Pretekali ste na tomto okruhu?</h3>

            <div className='select-option'>
                <div><input type="radio" name="ucast" id="ano" value="nie" checked={ucast === "nie"} onChange={() => { setucast("nie") }} /> <label htmlFor="nie">Nie</label></div>
                <div><input type="radio" name="ucast" id="ano" value="ano" checked={ucast === "ano"} onChange={() => { setucast("ano") }} /> <label htmlFor="ano">Áno</label></div>
            </div>
            <p className="text">Váš čas na okruhu použijeme na prvotné zadelenie do skupín. V prípade že ste na okruhu ešte nepretekali, zvyčajne to znamená že budete pretekať v kategórií "Začiatočníci"</p>
            <AnimatePresence>
                {ucast === "ano" && <motion.div className='lap-time' initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -50 }} transition={{ duration: 0.2 }}>
                    <span className='title' >Čas jedného kola</span>
                    <input className="box-shadow form-input" defaultValue={lapTime} onInput={setlapTime} placeholder='mm:ss' type="text" />
                </motion.div>}
            </AnimatePresence>

        </div>
    )
}
