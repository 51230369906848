import React from 'react'
import add from '../images/Add.png'

export default function InvitationSuccesfullScreen() {
    return (
        <div className="main-component-wraper after-invitation-success cta">
            <div className="main-component">
                <div className="home-row">
                    <div className="col">
                        <img src={add} alt="add" className='add' />
                        <h1>Pozvánka prijatá</h1>
                        <p>Užite si zábavu s aplikáciou GoRide</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
