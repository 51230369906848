import React from 'react'

export default function ProgressBar({ page, motion }) {
    return (
        <div className="progress">
            <div className="progress-wrap">


                <motion.div layout className="progress-item">
                    <div className="step-wrap">

                        <div className="step">
                            <span>1</span>
                        </div>

                    </div>

                    <div className="title">Motorka</div>
                </motion.div>

                <motion.div className={`${page > 1 && "passed-step"} progress-item`}>
                    <div className="step-wrap">
                        <span className='line'></span>
                        <div className="step">
                            <span>2</span>
                        </div>
                    </div>

                    <div className="title">Okruh</div>
                </motion.div>

                <motion.div layout className={`${page > 2 && "passed-step"} progress-item`}>
                    <div className="step-wrap">
                        <span className='line'></span>
                        <div className="step">
                            <span>3</span>
                        </div>
                    </div>

                    <div className="title">Platba</div>
                </motion.div>

                <motion.div layout className={`${page > 3 && "passed-step"} progress-item`}>
                    <div className="step-wrap">

                        <div className="step">
                            <span>4</span>
                        </div>
                        <div className='line'></div>
                    </div>

                    <div className="title">Hotovo</div>
                </motion.div>


            </div>
        </div>
    )
}
