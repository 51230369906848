import React from 'react'

export default function Page4({ email, registrationId, eventId, eventName }) {




    return (
        <>
            <div className='summary'>
                <div className='message'>
                    <div className='title' >Registrácia dokončená</div>
                </div>

                <div className="download">
                    <div>
                        <img src="" alt="" />

                        <p>Na adresu {email} bol odoslaný mail s prihláškou a ďalšími pokynmi</p><br />
                        {/*<DownloadPdf registrationId={registrationId} eventId={eventId} eventName={eventName}></DownloadPdf>*/}

                    </div>
                </div>

            </div>

        </>
    )
}
