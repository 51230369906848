import React, { useEffect, useState } from 'react'
import EventCard from '../components/EventCard'
import { getFirestore, getDocs, collection, query, orderBy, limit, where } from '@firebase/firestore';
import { motion } from "framer-motion"
import 'react-input-range/lib/css/index.css'
import AnimatedRoute from '../routes/AnimatedRoute';
import RegistrationCard from '../components/RegistrationCard';
import EventSliderCard from '../components/EventSliderCard';

export default function EventsScreenGrid() {

    const variants = {
        initial: { opacity: 0, y: 100 },
        animate: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * .05
            }
        }),
    }


    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [range, setrange] = useState({ min: 1, max: 5 })

    async function loadData() {
        setloading(true)
        const currentDate = new Date()
        const db = getFirestore()
        var rangeArr = []
        for (var i = range.min; i <= range.max; i++) {
            rangeArr.push(i)
        }


        const q = query(collection(db, "events_grid"),
            where("date", ">", currentDate.getSeconds()),
            where("status", "==", "published"),
            where('difficulty', 'in', rangeArr),
            limit(6),
            orderBy("date", "asc"),
        )

        const snapshot = await getDocs(q)

        if (!snapshot.empty) {
            var ret = []
            snapshot.forEach((doc) => {
                var poi = doc.data()
                poi.date = new Date(poi.date).toLocaleDateString()
                poi.docId = doc.id
                ret.push(poi)
            });
            setdata(ret)
        }
        setloading(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loadData()
    }, [])

    function HandleFilter() {

        loadData()
    }

    return (
        <AnimatedRoute>
            <div>
                <div className="content-wraper">
                    <div className="grid">
                        {!loading && data.map((doc, i) => {
                            return (
                                <motion.div
                                    variants={variants}
                                    initial="initial"
                                    animate="animate"
                                    custom={i}
                                    key={i}
                                >
                                    <EventSliderCard data={doc}></EventSliderCard>
                                </motion.div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </AnimatedRoute>
    )
}
