import React from 'react'
import error from "../images/Close.png"

export default function InvitationErrorScreen() {
    return (
        <div className="main-component-wraper after-invitation-success cta">
            <div className="main-component">
                <div className="home-row">
                    <div className="col">
                        <img src={error} alt="add" className='add' />
                        <h1>Niečo sa pokazilo</h1>
                        <p>Pozvánku sa nepodarilo overiť</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
