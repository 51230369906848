import React, { useState } from 'react';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { useAuth } from '../contexts/AuthContext';
import { AnimatePresence, motion } from 'framer-motion';
import Resizer from "react-image-file-resizer";
import { getFunctions, httpsCallable } from 'firebase/functions'
import errorIcon from '../images/error_icon-red.svg'


export default function UploadImage() {
    const [progress, setprogress] = useState();
    const [avatarChanged, setavatarChanged] = useState(false);
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState("");

    const { currentUser } = useAuth()
    const [selectedAvatar, setselectedAvatar] = useState(currentUser.data.avatar);
    const [imagePreview, setimagePreview] = useState(currentUser.data.avatar);

    const resizeFile = async (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                400,
                400,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });



    function loadFile(e) {

        e.preventDefault()
        seterror("")
        const file = e.target[0].files[0]


        uploadFile(selectedAvatar)
    }

    function uploadFile(file) {
        seterror("")
        if (!file) {
            seterror("Nieje zvolený žiaden súbor")
            return
        }
        setloading(true)
        const storage = getStorage()
        const storageRef = ref(storage, `/avatar/${currentUser.data.userId}/${file.name}`)
        const uploadTask = uploadBytesResumable(storageRef, file)

        uploadTask.on("state_changed", (snapshot) => {
            const prog = Math.trunc((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setprogress(prog)

        }, (err) => {
            seterror(err)
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {

                const functions = getFunctions()
                const changeAvatar = httpsCallable(functions, 'changeAvatar');
                changeAvatar({ userId: currentUser.data.userId, "url": url }).then(r => {

                    setprogress(0)
                    setavatarChanged(false)
                    setloading(false)
                }).catch(err => {
                    seterror(err)
                    setloading(false)
                })

            })
        })
    }

    async function setnewAvatar(e) {
        seterror("")

        const new_avatar = await resizeFile(e.target.files[0])

        const avatar_uri = URL.createObjectURL(new_avatar)
        setselectedAvatar(new_avatar)
        setimagePreview(avatar_uri)


        setavatarChanged(true)
    }


    return (

        <div className='upload-image'>
            <form onSubmit={loadFile}>
                <div className="avatar-wrap">

                    <div>
                        <img alt="avatar" className='avatar' src={imagePreview} />
                        <label htmlFor="file" className='camera-icon-wrap' >

                            <div className='camera-icon' >
                                <svg xmlns="http://www.w3.org/2000/svg" width="30.867" height="25.618" viewBox="0 0 30.867 25.618">
                                    <g id="camera-icon" transform="translate(124.055 -268.384)">
                                        <g id="_3535154801543238865" data-name="3535154801543238865" transform="translate(-123.055 269.384)">
                                            <path id="Path_72" data-name="Path 72" d="M29.867,23.994a2.624,2.624,0,0,1-2.624,2.624H3.624A2.624,2.624,0,0,1,1,23.994V9.561A2.624,2.624,0,0,1,3.624,6.936H8.873L11.5,3H19.37l2.624,3.936h5.248a2.624,2.624,0,0,1,2.624,2.624Z" transform="translate(-1 -3)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                            <circle id="Ellipse_9" data-name="Ellipse 9" cx="4.986" cy="4.986" r="4.986" transform="translate(9.364 8.037)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                        </g>
                                    </g>
                                </svg>
                            </div>

                        </label>
                    </div>



                    <input type="file" name="file" id="file" onChange={(e) => { setnewAvatar(e) }} />
                    <AnimatePresence>
                        {avatarChanged &&

                            <motion.button
                                type="submit"
                                className='button-primary'
                                initial={{ y: -20, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -20, opacity: 0 }}
                                disabled={loading}
                            >{
                                    !loading ? "Uložiť" : "Ukladám..."}</motion.button>
                        }
                    </AnimatePresence>
                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                </div>
            </form>
        </div>
    );

}
