import React from 'react'

export default function Page3({ price, paymentMethod, setpaymentMethod, understand, setunderstand }) {


    return (
        <div className='payment'>

            <h2 >Spôsob platby</h2>

            <div className='select-option'>
                <div><input type="radio" name="platba" checked={paymentMethod === "hotovost"} onChange={() => { setpaymentMethod("hotovost") }} id="hotovost" value="hotovost" /> <label htmlFor="hotovost"> Platba v hotovosti na mieste</label></div>
                <div><input type="radio" name="platba" checked={paymentMethod === "karta"} onChange={() => { setpaymentMethod("karta") }} id="karta" value="karta" /> <label htmlFor="karta">Platba kartou</label></div>
            </div>

            <div className='price-notice'>
                <h2 >Registračný poplatok: <span className='amount' >{price} €</span> </h2>

            </div>

            <div className="understand">
                <input type="checkbox" checked={understand} onChange={() => { setunderstand(!understand) }} /> <div>Súhlasím so <b>spracovaním osobných údajov</b> a <b>obchodnými podminkami</b> spoločnosti Go Ride.</div>
            </div>

        </div>
    )
}
