import './App.css';
import {
  BrowserRouter,
} from "react-router-dom";

import { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './contexts/ToastContext';
import { LoginProvider } from './contexts/LoginContext';
import Stripe from './contexts/Stripe';
import CustomRouter from './routes/CustomRouter';
import { useState } from 'react';
import Messages from './contexts/Messages';

function App() {


  return (
    <BrowserRouter>
      <AuthProvider>
        <ToastProvider>
          <LoginProvider>
            <Stripe>
              <CustomRouter />
            </Stripe>
          </LoginProvider>
        </ToastProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
