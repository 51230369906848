import React, { useRef, useState } from 'react'
import { motion } from 'framer-motion'
import Backdrop from './Backdrop'
import { useAuth } from '../contexts/AuthContext'
import errorIcon from '../images/error_icon-red.svg'
import { FirebaseValidation } from '../contexts/Validation'
import { useNavigate } from 'react-router'
import LoadingButton from '../components/LoadingButton'


export default function Modal({ handleClose }) {
    const { login } = useAuth();
    const [loading, setloading] = useState(false)
    const dropIn = {
        hidden: {
            opacity: "0"
        },
        visible: {

            opacity: "1",
        },
    }

    async function HandleSubmit() {
        if (!emailRef.current.value) {
            seterror("Email je prázdny")
            return
        }

        if (!passwordRef.current.value) {
            seterror("Heslo je prázdne")
            return
        }

        setloading(true)

        const poi = login(emailRef.current.value, passwordRef.current.value).then((user) => {

            setloading(false)
            handleClose()

        }).catch((err) => {
            setloading(false)
            seterror(FirebaseValidation(err.code))
        })

    }

    const navigate = useNavigate()
    const emailRef = useRef()
    const passwordRef = useRef()
    const [error, seterror] = useState()

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            HandleSubmit()
        }
    }


    return (

        <Backdrop onClick={handleClose}>
            <motion.div
                onClick={(e) => { e.stopPropagation() }}
                className="modal card login-body"
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ ease: 'easeIn', duration: 0.1 }}
            >

                <h2 className="login-header">Prihlásiť sa</h2>
                {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                <input className="form-input" placeholder="E-mail" type="email" id="email" onKeyPress={handleKeyDown} ref={emailRef} />
                <input className="form-input" placeholder="Heslo" type="password" id="password" onKeyPress={handleKeyDown} ref={passwordRef} />
                <span className="zabudnute-heslo">Zabudli ste heslo?</span>

                <LoadingButton className="button-login" text="Prihlásiť sa" textLoading="Čakajte prosím" onClick={() => { HandleSubmit() }} loading={loading} ></LoadingButton>
                <span className="nemate-ucet">Nemáte účet? <div onClick={() => { navigate('/novy-ucet'); handleClose() }} className="link-highlighted" >Vytvoriť účet</div></span>
            </motion.div>
        </Backdrop>
    )
}
