import React, { useEffect } from 'react'
import AnimatedRoute from '../routes/AnimatedRoute'
import hand from '../images/hand-home.png'
import calendar from '../images/Calendar.png'
import add from '../images/Add.png'
import EventsScreenGrid from './EventsScreenGrid'
import { useNavigate } from 'react-router'


export default function HomeScreen() {

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <AnimatedRoute>
            <div className="main-component-wraper home">
                <div className="home-screen main-component">
                    {/*<ImageSlideShowNews></ImageSlideShowNews>
                    
                    <UpcomingEventsSlider></UpcomingEventsSlider>*/}
                    <div className="intro home-row">
                        <div className="col">
                            <h1>Menej čakania, <span className="red"> viac
                                zážitku z jazdy</span>
                            </h1>
                            <p>
                                Nájdite si pretek priamo pre vás a realizujte
                                registráciu online
                            </p>

                            <div className="button-with-icon" onClick={() => { navigate("zoznam-eventov") }}>
                                <div className="content-wrap">
                                    <img src={calendar} alt="calendar" />
                                    <span>Zoznam pretekov</span>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <img src={hand} alt="hand" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-component-wraper">
                <div className="main-component">
                    <div className="home-row">
                        <div className="col">
                            <h1>Najbližšie preteky</h1>
                            <EventsScreenGrid></EventsScreenGrid>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-component-wraper cta">
                <div className="main-component">
                    <div className="home-row">
                        <div className="col">
                            <img src={add} alt="add" className='add' />
                            <h1>Registrujte sa s ľahkosťou!</h1>
                            <p>Registrujte sa na Váš obľúbený event rýchlo a jednoducho! </p>
                            <div className='button-with-icon-wrap' onClick={() => { navigate("zoznam-eventov") }}>
                                <div className="button-with-icon">
                                    <div className="content-wrap">
                                        <img src={calendar} alt="calendar" />
                                        <span>Zoznam pretekov</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </AnimatedRoute>

    )
}
