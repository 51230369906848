import { doc, getDoc, getFirestore, query, where, limit, getDocs, collection, orderBy } from '@firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Loading from '../components/loading/Loading'
import { useAuth } from '../contexts/AuthContext'
import { useLogin } from "../contexts/LoginContext"
import Notification from '../components/liveDataScreen/Notification'
import RegistrationLive from '../components/liveDataScreen/RegistrationLive'
import { icons } from '../routes/ResultsIcons'
import Regrouping from '../components/liveDataScreen/Regrouping'


export default function LiveDataScreen() {
    var { eventName } = useParams()
    const [data, setdata] = useState()
    const [loading, setloading] = useState(true)
    const [registration, setregistration] = useState()
    const [notifications, setnotifications] = useState()
    const [regrouping, setregrouping] = useState()
    const [timeHarmonogram, settimeHarmonogram] = useState()

    const { currentUser } = useAuth()
    const { openLogin } = useLogin()

    const navigate = useNavigate()
    const [alreadyRegistered, setalreadyRegistered] = useState(false)


    function parseTime(time) {

        var min = time % 60
        var hod = Math.floor(time / 60);

        return `${hod < 10 ? "0" : ""}${hod}:${min < 10 ? "0" : ""}${min}`

    }

    useEffect(() => {

        async function loadData() {
            if (currentUser) {



                const db = getFirestore()
                const docRef = doc(db, "events_grid", eventName)
                const registrationQery = query(collection(db, `events_grid/${eventName}/registrations`), where('rider.riderId', '==', currentUser.data.userId), limit(1))
                const notificationsQuery = query(collection(db, `events_grid/${eventName}/notifications`), orderBy('date', 'asc'))
                const regroupingRef = query(collection(db, `events_grid/${eventName}/regrouping`), where('status', '==', 'published'), orderBy('created', 'desc'))


                const eventRef = await getDoc(docRef)
                if (eventRef.exists()) {
                    const poi = eventRef.data()
                    poi.date = new Date(poi.date).toLocaleDateString()
                    poi.eventId = eventName
                    setdata(poi)
                    console.log(poi)

                } else {
                    navigate("/404")
                }

                if (currentUser) {
                    const registrationSnap = await getDocs(registrationQery)

                    if (!registrationSnap.empty) {
                        const poi = registrationSnap.docs[0].data()
                        setregistration(poi)
                    }


                    const notificationsSnap = await getDocs(notificationsQuery)
                    if (!notificationsSnap.empty) {
                        var poi = []
                        notificationsSnap.docs.forEach(item => {
                            poi.push(item.data())
                        })
                        setnotifications(poi)

                    }

                    const regroupingSnap = await getDocs(regroupingRef)
                    if (!regroupingSnap.empty) {
                        var poi = []
                        regroupingSnap.docs.forEach(item => {
                            poi.push(item.data())
                        })
                        setregrouping(poi)

                    }
                }
            }

            setloading(false)
        }


        loadData()


        if (currentUser && currentUser.data.registered_for && currentUser.data.registered_for.includes(eventName)) {
            setalreadyRegistered(true)
        }

    }, [])

    if (!currentUser) {
        return (
            <div className="not-registered">
                <h1>Pred zobrazením Live dát sa prosím prihláste</h1>
                <h3 onClick={() => { openLogin() }}>Prihlásiť sa</h3>
            </div>
        )
    }



    document.addEventListener("ready", function () {
        var chatHistory = document.querySelector(".notification-wrap");
        chatHistory.scrollTop = chatHistory.scrollHeight;
    }, false);

    if (!loading) {
        return (
            <>
                {!currentUser.data.registered_for.includes(eventName)
                    ? //Ak nieje pouzivatel registrovany na pretek
                    <div className="main-component-wraper">
                        <div className="main-component live-data">
                            <div className="section-title">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0001 10.9999C10.9001 10.9999 10.0001 11.8999 10.0001 12.9999C10.0001 14.0999 10.9001 14.9999 12.0001 14.9999C13.1001 14.9999 14.0001 14.0999 14.0001 12.9999C14.0001 11.8999 13.1001 10.9999 12.0001 10.9999ZM18.0001 12.9999C18.0001 9.43995 14.8901 6.59995 11.2501 7.04995C8.63005 7.36995 6.47005 9.45995 6.07005 12.0699C5.74005 14.2199 6.56005 16.1799 8.00005 17.4699C8.48005 17.8999 9.23005 17.7999 9.56005 17.2399L9.57005 17.2299C9.81005 16.8099 9.71005 16.2999 9.35005 15.9699C8.32005 15.0399 7.76005 13.5999 8.13005 12.0299C8.46005 10.6099 9.61005 9.45995 11.0301 9.11995C13.6501 8.48995 16.0001 10.4699 16.0001 12.9999C16.0001 14.1799 15.4801 15.2299 14.6701 15.9599C14.3101 16.2799 14.2001 16.7999 14.4401 17.2199L14.4501 17.2299C14.7601 17.7599 15.4801 17.9199 15.9501 17.5099C17.2001 16.4099 18.0001 14.7999 18.0001 12.9999ZM10.8301 3.06995C6.21005 3.58995 2.48005 7.39995 2.05005 12.0299C1.70005 15.7299 3.37005 19.0499 6.07005 21.0399C6.55005 21.3899 7.23005 21.2399 7.53005 20.7299C7.78005 20.2999 7.67005 19.7399 7.27005 19.4399C4.99005 17.7499 3.62005 14.8899 4.11005 11.7399C4.65005 8.23995 7.57005 5.44995 11.0901 5.05995C15.9101 4.50995 20.0001 8.27995 20.0001 12.9999C20.0001 15.6499 18.7101 17.9799 16.7301 19.4399C16.3301 19.7399 16.2201 20.2899 16.4701 20.7299C16.7701 21.2499 17.4501 21.3899 17.9301 21.0399C20.4001 19.2199 22.0001 16.2999 22.0001 12.9999C22.0001 7.08995 16.8701 2.37995 10.8301 3.06995Z" fill="#323232" />
                                </svg>
                                <h1>Live dáta</h1>
                            </div>
                            <div className="not-registered">
                                <h1>Nieste registrovaný na pretek {data.title}</h1>
                                <p>Bez vytvorenej registrácie sa Vám Live dáta nezobrazia</p>
                                <h3 onClick={() => { navigate(`/detail-eventu/${eventName}/`) }}>Vytvoriť registráciu</h3>
                            </div>
                        </div>
                    </div>

                    : //Ak je pouzivatel registrovany na pretek

                    <div className="main-component-wraper live-data">
                        <div className="main-component">
                            <div className="section-title">
                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0001 10.9999C10.9001 10.9999 10.0001 11.8999 10.0001 12.9999C10.0001 14.0999 10.9001 14.9999 12.0001 14.9999C13.1001 14.9999 14.0001 14.0999 14.0001 12.9999C14.0001 11.8999 13.1001 10.9999 12.0001 10.9999ZM18.0001 12.9999C18.0001 9.43995 14.8901 6.59995 11.2501 7.04995C8.63005 7.36995 6.47005 9.45995 6.07005 12.0699C5.74005 14.2199 6.56005 16.1799 8.00005 17.4699C8.48005 17.8999 9.23005 17.7999 9.56005 17.2399L9.57005 17.2299C9.81005 16.8099 9.71005 16.2999 9.35005 15.9699C8.32005 15.0399 7.76005 13.5999 8.13005 12.0299C8.46005 10.6099 9.61005 9.45995 11.0301 9.11995C13.6501 8.48995 16.0001 10.4699 16.0001 12.9999C16.0001 14.1799 15.4801 15.2299 14.6701 15.9599C14.3101 16.2799 14.2001 16.7999 14.4401 17.2199L14.4501 17.2299C14.7601 17.7599 15.4801 17.9199 15.9501 17.5099C17.2001 16.4099 18.0001 14.7999 18.0001 12.9999ZM10.8301 3.06995C6.21005 3.58995 2.48005 7.39995 2.05005 12.0299C1.70005 15.7299 3.37005 19.0499 6.07005 21.0399C6.55005 21.3899 7.23005 21.2399 7.53005 20.7299C7.78005 20.2999 7.67005 19.7399 7.27005 19.4399C4.99005 17.7499 3.62005 14.8899 4.11005 11.7399C4.65005 8.23995 7.57005 5.44995 11.0901 5.05995C15.9101 4.50995 20.0001 8.27995 20.0001 12.9999C20.0001 15.6499 18.7101 17.9799 16.7301 19.4399C16.3301 19.7399 16.2201 20.2899 16.4701 20.7299C16.7701 21.2499 17.4501 21.3899 17.9301 21.0399C20.4001 19.2199 22.0001 16.2999 22.0001 12.9999C22.0001 7.08995 16.8701 2.37995 10.8301 3.06995Z" fill="#323232" />
                                </svg>
                                <h1>Live dáta</h1>
                            </div>

                            <div className="event-name">
                                <h1>{data.title}</h1>
                                <h4>({data.date})</h4>
                            </div>

                            <div className="live-content">
                                <div className="card registration">
                                    <h2 className='heading'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5ZM19 19.09H5V4.91H19V19.09ZM6 15H18V17H6V15ZM6 11H18V13H6V11ZM6 7H18V9H6V7Z" fill="#323232" />
                                        </svg>
                                        Moja registrácia
                                    </h2>
                                    <RegistrationLive registration={registration} />
                                </div>

                                <div className="card">
                                    <h2 className='heading'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 4V11H5.17L4.58 11.59L4 12.17V4H15ZM16 2H3C2.45 2 2 2.45 2 3V17L6 13H16C16.55 13 17 12.55 17 12V3C17 2.45 16.55 2 16 2ZM21 6H19V15H6V17C6 17.55 6.45 18 7 18H18L22 22V7C22 6.45 21.55 6 21 6Z" fill="#323232" />
                                        </svg>
                                        <span>Notifikácie</span>
                                    </h2>

                                    <div className="notification-wrap">
                                        {
                                            notifications
                                                ? ( // Ak sú notifikácie
                                                    notifications.map((item, i) => {
                                                        return (
                                                            <Notification i={i} message={item.message} sender={item.sender} time={item.date}></Notification>
                                                        )
                                                    })
                                                )
                                                :
                                                (   // Ak niesú notifikácie
                                                    <div>Zatiaľ žiadne správy</div>
                                                )
                                        }
                                    </div>

                                </div>

                                <div className="card results">
                                    <h2 className='heading'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 5H17V3H7V5H5C3.9 5 3 5.9 3 7V8C3 10.55 4.92 12.63 7.39 12.94C8.02 14.44 9.37 15.57 11 15.9V19H7V21H17V19H13V15.9C14.63 15.57 15.98 14.44 16.61 12.94C19.08 12.63 21 10.55 21 8V7C21 5.9 20.1 5 19 5ZM5 8V7H7V10.82C5.84 10.4 5 9.3 5 8ZM19 8C19 9.3 18.16 10.4 17 10.82V7H19V8Z" fill="#323232" />
                                        </svg>
                                        Výsledky
                                    </h2>
                                    <div className="results-wrap">
                                        {data.category && data.category.length > 0 && data.results ?
                                            data.category.map((item, i) => {
                                                return (
                                                    <div key={i} className="category">
                                                        <h3>{item.fullName}</h3>
                                                        {icons.map((icon, j) => {
                                                            if (data.results && data.results[item.shortenName] && data.results[item.shortenName][`place${j + 1}`]) {
                                                                var rider = data.results[item.shortenName][`place${j + 1}`]
                                                                return (
                                                                    <div className="place" key={j}>
                                                                        {icon}
                                                                        {rider ?
                                                                            <div>{rider.riderNumber} - {rider.riderName} </div>
                                                                            :
                                                                            <div>Žiadne výsledky</div>

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            console.log(item.shortenName, data.results)
                                                        })}
                                                    </div>
                                                )
                                            })
                                            : <div>Organizátor zatiaľ neuverejnil výsledky</div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="regrouping time-harmonogram">
                                <div className="card">
                                    <h2 className='heading'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.01 8L10 8L10 10L17.01 10V13L21 9L17.01 5L17.01 8ZM3 15L6.99 19V16L14 16L14 14L6.99 14L6.99 11L3 15ZM17.01 8L10 8L10 10L17.01 10V13L21 9L17.01 5L17.01 8ZM3 15L6.99 19V16L14 16L14 14L6.99 14L6.99 11L3 15Z" fill="#323232" />
                                        </svg>
                                        Regrouping
                                    </h2>
                                    {regrouping && regrouping.length > 0 ?
                                        <Regrouping data={regrouping} /> :
                                        <div>Zatiaľ žiadne dáta</div>
                                    }
                                </div>

                                <div className="card">
                                    <h2 className='heading'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 7H17V9H11V7ZM11 11H17V13H11V11ZM11 15H17V17H11V15ZM7 7H9V9H7V7ZM7 11H9V13H7V11ZM7 15H9V17H7V15ZM3 3V21H21V3H3ZM19 19H5V5H19V19Z" fill="#323232" />
                                        </svg>
                                        Časový harmonogram
                                    </h2>
                                    <div className="harmonogram-items">
                                        {data.harmonogram ? data.harmonogram.map((item, i) => (
                                            <div className="harmonogram-item-wrap box-shadow" key={i}>
                                                <div className="id"><b>{i + 1}</b></div>
                                                <div className='title'>{item.title}</div>
                                                <div className="start-end"><b>{parseTime(item.start)} - {parseTime(item.end)}</b></div>
                                            </div>
                                        )) : <div>Zatiaľ žiadne dáta</div>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </>
        )
    } else { return <Loading /> }
}
