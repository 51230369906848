import React, { useEffect, useRef, useState } from 'react'
import EventCard from '../components/EventCard'
import { getFirestore, getDocs, collection, query, orderBy, limit, where, startAt, endAt, startAfter } from '@firebase/firestore';
import { motion } from "framer-motion"
import SearchForEvent from '../components/SearchForEvent';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css'
import AnimatedRoute from '../routes/AnimatedRoute';
import LoadingButton from '../components/LoadingButton'

export default function EventsScreen() {

    const variants = {
        initial: { opacity: 0, y: 100 },
        animate: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * .05
            }
        }),
    }


    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [range, setrange] = useState({ min: 1, max: 5 })
    const [lastDoc, setlastDoc] = useState()
    const [loadingMore, setloadingMore] = useState(false)
    const [isLastPage, setisLastPage] = useState(false)

    const gridRef = useRef()

    const db = getFirestore()
    const currentDate = new Date()


    async function loadData() {
        setloading(true)

        var rangeArr = []
        for (var i = range.min; i <= range.max; i++) {
            rangeArr.push(i)
        }

        const q = query(collection(db, "events_grid"),
            where("date", ">", currentDate.getTime()),
            where("status", "==", "published"),
            where('difficulty', 'in', rangeArr),
            orderBy("date", "asc"),
            limit(6)
        )

        const snapshot = await getDocs(q)

        if (!snapshot.empty) {
            var ret = []
            snapshot.forEach((doc) => {
                var poi = doc.data()
                poi.date = new Date(poi.date).toLocaleDateString()
                poi.docId = doc.id
                ret.push(poi)
            });
            setlastDoc(snapshot.docs[snapshot.docs.length - 1])
            setdata(ret)
        }

        setloading(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        loadData()
    }, [])

    function HandleFilter() {
        loadData()
    }

    async function loadMore() {
        setloadingMore(true)
        var rangeArr = []
        for (var i = range.min; i <= range.max; i++) {
            rangeArr.push(i)
        }

        const q = query(collection(db, "events_grid"),
            where("date", ">", currentDate.getTime()),
            where("status", "==", "published"),
            where('difficulty', 'in', rangeArr),
            orderBy("date", "asc"),
            startAfter(lastDoc),
            limit(6)
        )

        const snapshot = await getDocs(q)

        if (!snapshot.empty) {
            var ret = [...data]
            snapshot.forEach((doc) => {
                var poi = doc.data()
                poi.date = new Date(poi.date).toLocaleDateString()
                poi.docId = doc.id
                ret.push(poi)
            });
            if (snapshot.docs.length < 6) setisLastPage(true)
            setlastDoc(snapshot.docs[snapshot.docs.length - 1])
            setdata(ret)
        }
        setloadingMore(false)
    }

    return (
        <AnimatedRoute>
            <div className='main-component-wraper' >
                <div className="events main-component">
                    <div className='filter-wrap'>
                        <div className='filter'>
                            <h1>Filter</h1>
                            <SearchForEvent />
                            <div className='difficulty'>
                                <h3>Náročnosť</h3>
                                <InputRange
                                    maxValue={5}
                                    minValue={1}
                                    value={range}
                                    onChange={value => setrange(value)}
                                    allowSameValues
                                />

                            </div>
                            <button className="button-primary" onClick={HandleFilter}>Aktualizovať</button>
                        </div>
                    </div>
                    <div>
                        <h1>Zoznam Pretekov</h1>

                        <div className="content-wraper">
                            <div className="grid">
                                {data.length > 0 && data.map((doc, i) => {
                                    return (
                                        <motion.div
                                            /*variants={variants}
                                            initial="initial"
                                            animate="animate"
                                            custom={i}*/
                                            key={i}
                                            whileHover={{ y: -3, zIndex: 5 }} >
                                            <EventCard data={doc} />
                                        </motion.div>
                                    )
                                })}
                            </div>
                        </div>
                        {!isLastPage && <div className="load-more">
                            <LoadingButton onClick={() => { loadMore() }} loading={loadingMore} text="Načítať viac" textLoading="Načítavam" className="load-more button-primary"></LoadingButton>
                        </div>}
                    </div>
                </div>
            </div>
        </AnimatedRoute>
    )
}
