import React, { useState } from 'react'
import RegroupingChange from './RegroupingChange'

export default function Regrouping({ data }) {
    const [selected, setselected] = useState({ name: "" })

    function HandleSelect(item) {
        setselected(item)
    }

    return (
        <div className="regrouping">
            <div className="regrouping-name-wrap">
                {data.map((item, i) => {
                    return (
                        <div key={i} className={`regrouping-name ${item.name === selected.name ? "selected" : ""}`} onClick={() => { HandleSelect(item) }}>
                            {item.name}
                        </div>
                    )
                })}
            </div>

            {!selected.name ? <div>Vyberte</div> : (
                <div className="changes-wrap">{
                    (
                        selected.changes.map((item, i) => {
                            return (
                                <RegroupingChange i={i} rider={item} />
                            )
                        })
                    )
                }

                </div>
            )}
        </div>
    )
}
