import React from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {Elements} from '@stripe/react-stripe-js'


const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

export default function Stripe({children}) {
    return (
        <>
            <Elements stripe={stripePromise}>{children}</Elements>
        </>
    )
}
