import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import RegistrationCard from '../components/RegistrationCard'
import AnimatedRoute from '../routes/AnimatedRoute'
import { motion } from 'framer-motion'
import CancelRegistrationPopup from '../components/CancelRegistrationPopup copy'

export default function MyRegistrationsScreen() {
    const [disqualifyPopup, setdisqualifyPopup] = useState(false)
    const [dataInPopup, setdataInPopup] = useState()

    const variants = {
        initial: { opacity: 0, y: 100 },
        animate: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * .05
            }
        }),
    }

    function openPopup(data) {

        setdisqualifyPopup(data)
    }


    const { currentUser } = useAuth()
    if (currentUser && currentUser.data.registered_for) {
        var arr = [...currentUser.data.registered_for].reverse();

    }


    return (
        <AnimatedRoute>
            {disqualifyPopup && <CancelRegistrationPopup rider={disqualifyPopup} close={() => { setdisqualifyPopup(null) }} />}
            <div className='main-component-wraper'>
                <div className="my-registrations main-component">
                    <h1>Moje registrácie</h1>
                    <div className="registrations-grid-wrap">
                        <div className="registrations-grid">
                            {currentUser ? <>
                                {arr && arr.length > 0 ? arr.map((item, i) => {
                                    if (item !== '') {
                                        return (
                                            <motion.div
                                                variants={variants}
                                                initial="initial"
                                                animate="animate"
                                                custom={i}
                                                key={i}
                                            >
                                                <RegistrationCard cancelRegistration={openPopup} keyNum={i} r={item} uid={currentUser.data.userId}></RegistrationCard>
                                            </motion.div>
                                        )
                                    }
                                }) :
                                    <p>Zatiaľ žiadne registrácie</p>
                                }
                            </>
                                :
                                <div>Pre zobrazenie registrácií sa prosím prihláste</div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </AnimatedRoute>
    )
}
