import React, { useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import errorIcon from '../images/error_icon-red.svg'
import logo from '../images/go_ride_logo_1.png'
import { getFirestore, doc, updateDoc } from '@firebase/firestore';
import Motorcycle from "../components/EditAccount/Motorcycles"
import UploadImage from '../components/UploadImage';
import { getFunctions, httpsCallable } from 'firebase/functions';


const animation = {
    hidden: {
        opacity: 0,
        y: -50,
        transition: {
            duration: 0.3,
        },
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.3
        }
    },

    swipeInitial: {
        opacity: 0,
        x: 100
    },
    swipeAction: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.2
        }
    },

    swipeExit: {
        opacity: 0,
        x: -100,
        transition: {
            duration: 0.2
        }
    }
}

export default function InitializeAccount() {
    const FirstNameRef = useRef()
    const LastNameRef = useRef()
    const TelRef = useRef()
    const [error, seterror] = useState()
    const [showSubmit, setshowSubmit] = useState(false);
    const [firstName, setfirstName] = useState();
    const [lastName, setlastName] = useState();

    const [page, setpage] = useState(1);
    const [tel, settel] = useState();

    const { currentUser, logout } = useAuth()

    function HandleLogout() {
        logout()
        window.location.reload(false)
    }

    function HandleValidation() {
        const Tel = TelRef.current.value

        if (Tel) {
            setshowSubmit(true)
            settel(Tel)
        } else {
            setshowSubmit(false)
        }

    }

    function HandleValidationName() {
        const firstname = FirstNameRef.current.value
        const lastname = LastNameRef.current.value

        if (firstname && lastname) {
            setshowSubmit(true)
            setfirstName(firstname)
            setlastName(lastname)

        } else {
            setshowSubmit(false)
        }
    }

    async function HandleSubmit() {
        seterror("")

        const db = getFirestore()
        updateDoc(doc(db, "users", currentUser.data.userId), {
            "tel": `${tel ? tel : ""}`,
            "firstname": firstName,
            "lastname": lastName

        }).then(() => {
            setpage(5)
        })
    }

    function HandleFinish() {
        const db = getFirestore()
        const functions = getFunctions()
        const onNewUserRegistered = httpsCallable(functions, "onNewUserRegistered")

        onNewUserRegistered({ userId: currentUser.data.userId })

        updateDoc(doc(db, "users", currentUser.data.userId), {
            "status": "active",
        })



    }

    return (

        <div className="initialize-wrap" page={page}>
            <AnimatePresence exitBeforeEnter>
                {page === 1 &&
                    <motion.div
                        className='first-page initialize-account'
                        variants={animation}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        key={1}
                    >

                        <img className='logo' src={logo} alt="logo" />
                        <h1>Vitajte v aplikácií Go Ride</h1>
                        <p>Na začiatok budeme potrebovať vyplniť tieto základné informácie.</p>

                        <motion.button
                            className='button-primary'
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -20, opacity: 0 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(2) }}
                        >
                            Poďme na to
                        </motion.button>

                    </motion.div>


                }

                {page === 2 &&
                    <motion.div
                        className='first-page initialize-account'
                        variants={animation}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        key={2}
                    >

                        <UploadImage></UploadImage>
                        <h2>Ako vás máme volať?</h2>
                        <div className="form-wrap">
                            <input autoComplete='off' className="box-shadow form-input" placeholder="Meno" type="text" id="tel" ref={FirstNameRef} onChange={HandleValidationName} />
                            <input autoComplete='off' className="box-shadow form-input" placeholder="Priezvisko" type="text" id="tel" ref={LastNameRef} onChange={HandleValidationName} />
                        </div>

                        {showSubmit && <motion.button
                            className='button-primary'
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -20, opacity: 0 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(3) }}
                        >
                            Ďalej
                        </motion.button>}

                    </motion.div>


                }

                {page === 3 && <motion.div
                    key={3}
                    className='initialize-account'
                    variants={animation}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                >

                    <h2>Telefónne číslo</h2>
                    <p>Telefónne číslo nám slúži na rýchlu kominukáciu s vami. <br /> Tento údaj nieje povinný</p>

                    {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                    <input autoComplete='off' className="box-shadow form-input" placeholder="Tel. číslo" type="tel" id="tel" ref={TelRef} onChange={HandleValidation} />

                    <AnimatePresence exitBeforeEnter>
                        {showSubmit && <motion.button
                            className='button-primary '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -20, opacity: 0 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(4) }}
                        >
                            Ďalej
                        </motion.button>}
                    </AnimatePresence>


                    <div className='logout' onClick={() => { HandleLogout() }}>Odhlásiť sa</div>
                </motion.div>}

                {page === 4 && <motion.div
                    className='initialize-account'
                    variants={animation}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    key={4}
                >

                    <h2>Vaše motorky</h2>
                    <p>Tu si môžete pridať motorky na ktorých budete pretekať.</p>

                    <div className='form-wraper'>
                        <Motorcycle motorcycles={currentUser.data.motorcycles} uid={currentUser.data.userId}></Motorcycle>
                    </div>
                    <AnimatePresence exitBeforeEnter>

                    </AnimatePresence>
                    <motion.button
                        className='button-primary '
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        onClick={() => { HandleSubmit() }}
                        whileTap={{ scale: 0.95 }}

                    >
                        Dokončiť
                    </motion.button>

                    <div className='logout' onClick={() => { HandleLogout() }}>Odhlásiť sa</div>
                </motion.div>}

                {page === 5 && <motion.div
                    className='initialize-account'
                    variants={animation}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    key={5}
                >
                    <img className='logo' src={logo} alt="logo" />
                    <h1>Všetko je pripravené!</h1>
                    <p>Prajeme príjamnú zábavu pri používaní aplikácie Go Ride</p>

                    <div className="buttons">

                        <motion.button
                            className='button-primary '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(6) }}
                        >
                            Ako používať Go Ride?
                        </motion.button>

                        <motion.button
                            className='button-default '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}

                            whileTap={{ scale: 0.95 }}
                            onClick={() => { HandleFinish() }}
                        >
                            Dokončiť
                        </motion.button>
                    </div>

                </motion.div>}

                {page === 6 && <motion.div
                    className='initialize-account'
                    variants={animation}
                    initial="hidden"
                    animate="visible"
                    exit="swipeExit"
                    key={6}
                >

                    <h2>Vyberte si Váš oblúbený pretek</h2>
                    <p>Preteky je možné zoradiť podľa úrovne náročnosti</p>

                    <div className="buttons">

                        <motion.button
                            className='button-default '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}

                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(page - 1) }}
                        >
                            Späť
                        </motion.button>

                        <motion.button
                            className='button-primary '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(page + 1) }}

                        >
                            Ďalej
                        </motion.button>
                    </div>

                </motion.div>}

                {page === 7 && <motion.div
                    className='initialize-account'
                    variants={animation}
                    initial="swipeInitial"
                    animate="swipeAction"
                    exit="swipeExit"
                    key={7}
                >

                    <h2>Prihlásenie na pretek</h2>
                    <p>Vyberte si motorku </p>

                    <div className="buttons">

                        <motion.button
                            className='button-default '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}

                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(page - 1) }}
                        >
                            Späť
                        </motion.button>

                        <motion.button
                            className='button-primary '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(page + 1) }}

                        >
                            Ďalej
                        </motion.button>
                    </div>

                </motion.div>}

                {page === 8 && <motion.div
                    className='initialize-account'
                    variants={animation}
                    initial="swipeInitial"
                    animate="swipeAction"
                    exit="swipeExit"
                    key={8}
                >

                    <h1>Registrácia pri evente</h1>
                    <p>Qr kód v maily...</p>

                    <div className="buttons">

                        <motion.button
                            className='button-default '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}

                            whileTap={{ scale: 0.95 }}
                            onClick={() => { setpage(page - 1) }}
                        >
                            Späť
                        </motion.button>


                        <motion.button
                            className='button-primary '
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => { HandleFinish() }}

                        >
                            Hotovo
                        </motion.button>
                    </div>

                </motion.div>}

                {page >= 6 &&
                    <h3 onClick={() => { HandleFinish() }} className='end-introduction'>
                        Preskočiť
                    </h3>
                }


            </AnimatePresence>
        </div>

    );
}
