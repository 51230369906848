import React, { useEffect, useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { doc, getDoc, getFirestore } from '@firebase/firestore'
import { useNavigate } from 'react-router'
import EditAccountInitial from '../components/EditAccount/EditAccountInitial'
import AnimatedRoute from '../routes/AnimatedRoute'

export default function AditionallDetailsScreen() {

    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const [userData, setuserData] = useState()

    useEffect(() => {

        window.scrollTo(0, 0)
    }, [])

    const { currentUser } = useAuth()
    useEffect(() => {
        if (currentUser) {
            async function loadData() {
                const db = getFirestore()
                const uid = currentUser.user.uid
                const docRef = doc(db, "users", uid)
                const docSnap = await getDoc(docRef)
                var poi = { ...docSnap.data() }

                setuserData(poi)
                setLoading(false)
            }

            loadData()
        }
        else {
            navigate("/novy-ucet")
        }

    }, [])


    return (
        <AnimatedRoute>
            <div className="main-component-wraper">
                <div className="main-component">
                    <EditAccountInitial userData={userData}></EditAccountInitial>
                </div>
            </div>
        </AnimatedRoute>
    )



}