import React from 'react'
import { useLocation, useNavigate } from 'react-router'
import AnimatedRoute from '../routes/AnimatedRoute'
import tick from '../images/Tick.png'


export default function AfterPaymentScreen() {

    const { state } = useLocation()

    const navigate = useNavigate()

    return (
        <AnimatedRoute>
            <div className="main-component-wraper after-payment">
                <div className="event-details-wrap main-component">
                    <div className="wrap">
                        <img src={tick} alt="completed" />
                        <h1>Vaša registrácia bola úspešne spracovaná</h1>
                        <p>Na váš email Vám o chvílu príde email s pokynmi a vygenerovaným PDF dokumentom, ktorým sa preukážate pri registrácií</p>
                        <button className='button-primary' onClick={() => { navigate("/") }}>Späť domov</button>
                    </div>
                </div>
            </div>
        </AnimatedRoute>
    )
}
