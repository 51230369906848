import { doc, getDoc, getFirestore } from '@firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import avatarIcon from "../images/avatar_icon_events.svg"
import raceLocationIcon from "../images/race_location_icon.svg"
import { AnimatePresence, motion } from 'framer-motion'
import Loading from '../components/loading/Loading'
import SignInForEventPopup from '../components/SignInForEventPopup'
import { useAuth } from '../contexts/AuthContext'
import { useLogin } from "../contexts/LoginContext"
import AnimatedRoute from '../routes/AnimatedRoute'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function EventDetailsScreen() {
    var { eventName } = useParams()
    const [data, setdata] = useState()
    const [loading, setloading] = useState(true)
    const [open, setopen] = useState(false)

    const x = [1, 2, 3, 4, 5]

    const { currentUser } = useAuth()
    const { openLogin } = useLogin()

    const navigate = useNavigate()
    const [alreadyRegistered, setalreadyRegistered] = useState(false)


    useEffect(() => {


        async function loadData() {

            const db = getFirestore()
            const docRef = doc(db, "events_grid", eventName)
            const d = await getDoc(docRef)

            if (d.exists()) {
                const poi = d.data()
                poi.date = new Date(poi.date).toLocaleDateString()
                poi.eventId = eventName
                setdata(poi)
                setloading(false)

            } else {
                navigate("/404")
            }
        }
        loadData()
        window.scrollTo(0, 0)
        if (currentUser && currentUser.data.registered_for && currentUser.data.registered_for.includes(eventName)) {
            setalreadyRegistered(true)
        }

    }, [])


    function parseTime(time) {

        var min = time % 60
        var hod = Math.floor(time / 60);

        return `${hod < 10 ? "0" : ""}${hod}:${min < 10 ? "0" : ""}${min}`

    }
    function HandleClose() {
        setopen(!open)
    }

    if (!loading) {
        return (
            <AnimatedRoute>
                <div className="main-component-wraper">
                    <div className="event-details-wrap main-component">

                        <div className="about-event">
                            <AnimatePresence>
                                {open && <SignInForEventPopup data={data} handleClose={() => { HandleClose() }}></SignInForEventPopup>}
                            </AnimatePresence>

                            <div className="col1">

                                <h1 className="event-title">{data.title}</h1>

                                <div className="date-location-wrap">
                                    <h3 className="event-date">{data.date}</h3>
                                    <div className="location-group details">
                                        <LazyLoadImage src={raceLocationIcon} alt="location-icon" className="location-icon" />
                                        <h3 className="location-text">{data.circuit_location}</h3>
                                    </div>
                                </div>

                                <div className="organizer">
                                    <img className='avatar' src={`${data.organizer.avatar !== undefined ? data.organizer.avatar : "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"}`} alt="avatar" />
                                    <div>
                                        <h3>{data.organizer.name}</h3>
                                        <span className="text">Organizátor</span>
                                    </div>
                                </div>

                                <h3>Náročnosť</h3>

                                <div className="difficulty">

                                    {x.map((item, i) => {

                                        return (

                                            <div key={i}>
                                                {i < data.difficulty ?
                                                    <svg className="dash red" xmlns="http://www.w3.org/2000/svg" width="25" height="5" viewBox="0 0 25 5">
                                                        <rect id="dificulty_line" width="25" height="5" rx="2.5" />
                                                    </svg>
                                                    :
                                                    <svg className="dash" xmlns="http://www.w3.org/2000/svg" width="25" height="5" viewBox="0 0 25 5">
                                                        <rect id="dificulty_line" width="25" height="5" rx="2.5" />
                                                    </svg>}
                                            </div>
                                        )

                                    })}

                                </div>

                                <div className="price">
                                    <h2>Cena za registráciu: <b>{data.price} €</b></h2>
                                </div>

                                <div className="cta-wrap">
                                    {!alreadyRegistered ?
                                        <motion.button

                                            whileTap={{ scale: 0.92 }}
                                            onClick={() => { if (currentUser) { setopen(true) } else { openLogin() } }}
                                            className="button-primary">Zapísať sa</motion.button>
                                        :
                                        <motion.button onClick={() => { navigate(`/detail-eventu/${eventName}/live`) }} whileTap={{ scale: 0.92 }} className="button-default">
                                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.0001 10.9999C10.9001 10.9999 10.0001 11.8999 10.0001 12.9999C10.0001 14.0999 10.9001 14.9999 12.0001 14.9999C13.1001 14.9999 14.0001 14.0999 14.0001 12.9999C14.0001 11.8999 13.1001 10.9999 12.0001 10.9999ZM18.0001 12.9999C18.0001 9.43995 14.8901 6.59995 11.2501 7.04995C8.63005 7.36995 6.47005 9.45995 6.07005 12.0699C5.74005 14.2199 6.56005 16.1799 8.00005 17.4699C8.48005 17.8999 9.23005 17.7999 9.56005 17.2399L9.57005 17.2299C9.81005 16.8099 9.71005 16.2999 9.35005 15.9699C8.32005 15.0399 7.76005 13.5999 8.13005 12.0299C8.46005 10.6099 9.61005 9.45995 11.0301 9.11995C13.6501 8.48995 16.0001 10.4699 16.0001 12.9999C16.0001 14.1799 15.4801 15.2299 14.6701 15.9599C14.3101 16.2799 14.2001 16.7999 14.4401 17.2199L14.4501 17.2299C14.7601 17.7599 15.4801 17.9199 15.9501 17.5099C17.2001 16.4099 18.0001 14.7999 18.0001 12.9999ZM10.8301 3.06995C6.21005 3.58995 2.48005 7.39995 2.05005 12.0299C1.70005 15.7299 3.37005 19.0499 6.07005 21.0399C6.55005 21.3899 7.23005 21.2399 7.53005 20.7299C7.78005 20.2999 7.67005 19.7399 7.27005 19.4399C4.99005 17.7499 3.62005 14.8899 4.11005 11.7399C4.65005 8.23995 7.57005 5.44995 11.0901 5.05995C15.9101 4.50995 20.0001 8.27995 20.0001 12.9999C20.0001 15.6499 18.7101 17.9799 16.7301 19.4399C16.3301 19.7399 16.2201 20.2899 16.4701 20.7299C16.7701 21.2499 17.4501 21.3899 17.9301 21.0399C20.4001 19.2199 22.0001 16.2999 22.0001 12.9999C22.0001 7.08995 16.8701 2.37995 10.8301 3.06995Z" fill="#323232" />
                                            </svg>
                                            <span>Live</span>
                                        </motion.button>
                                    }

                                </div>


                            </div>

                            <div className="col2">
                                <img src={data.details.image} alt="event_image" />
                            </div>

                        </div>

                        <div className="event-description">
                            <div className="col1">
                                <h1>O preteku</h1>
                                {data.details.description ? <div className='event-details-text' dangerouslySetInnerHTML={{ __html: data.details.description }}></div>

                                    : <div>Žiadne dáta</div>}

                            </div>

                            <div className="col1">
                                <h1>Časový harmonogram</h1>
                                <div className="harmonogram-items">
                                    {data.harmonogram ? data.harmonogram.map((item, i) => (
                                        <div className="harmonogram-item-wrap box-shadow" key={i}>
                                            <div className="id"><b>{i + 1}</b></div>
                                            <div className='title'>{item.title}</div>
                                            <div className="start-end"><b>{parseTime(item.start)} - {parseTime(item.end)}</b></div>
                                        </div>
                                    )) : <div>Žiadne dáta</div>}

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </AnimatedRoute>
        )
    } else { return <Loading /> }
}
