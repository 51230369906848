import React, { useCallback, useContext, useState } from 'react'
import { Toast } from '../components/Toast'

const toastCon = React.createContext()


export function ToastProvider(props) {

    const [showToast, setShowToast] = useState(false)
    const [message, setmessage] = useState(null)
    const [type, settype] = useState(null)

    const displayToast = useCallback(
        function (message, type) {
            setShowToast(true)
            setmessage(message)
            settype(type)

            setTimeout(() => {
                hideToast()
            }, 2000);
        }
        , [])

    function hideToast() {
        setShowToast(false)
    }

    return (
        <toastCon.Provider value={displayToast}>
            {showToast && <Toast type={type} message={message} close={hideToast}></Toast>}
            {props.children}
        </toastCon.Provider>
    )
}

export function useToast() {
    return useContext(toastCon);
}
