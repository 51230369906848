import React from 'react'

export default function RegistrationLive({ registration }) {
    return (
        <div className="body">
            <div className="wrap">
                <h3>Kategória</h3>
                <h4>{registration.category !== "" ? registration.category : "Zatiaľ neuvedená"}</h4>
            </div>

            <div className="wrap">
                <h3>
                    Štartovné číslo
                </h3>
                <h4>{registration.startNumber !== "" ? registration.startNumber : "Zatiaľ neuvedené"}</h4>
            </div>

            <div className="wrap motorka">
                <h3>Motorka</h3>
                <div>
                    <h4>Model: <b>{registration.motorcycle.model}</b></h4>
                    <h4>Kubatúra: <b>{registration.motorcycle.cubature} cm3</b></h4>
                </div>
            </div>
        </div>
    )
}
