import { AnimatePresence } from 'framer-motion'
import React, { useContext, useState } from 'react'
import Modal from '../components/LogInPopup'


const MyContext = React.createContext()

export function LoginProvider({children}){
    const [loginForm, setloginForm] = useState(false)

    const openLogin = ()=>{
        setloginForm(true)
    }

    const closeLogin = ()=>{
        setloginForm(false)
    }

    const value={
        openLogin,
        closeLogin
    }

    return (
        <MyContext.Provider value={value}>
            <AnimatePresence
                    initial={false}
                    exitBeforeEnter={true}
                    onExitComplete={()=>null}
                >
                    {loginForm&& <Modal loginForm ={loginForm} handleClose = {() =>{closeLogin()}}/>}
            </AnimatePresence>

            {children}
        </MyContext.Provider>
    )

}

export function useLogin(){
    return useContext(MyContext)
}