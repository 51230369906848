import React, { useState } from 'react'
import errorIcon from "../../images/error_icon.svg"
import { useAuth } from '../../contexts/AuthContext'
import Motorcycles from './Motorcycles'
import UploadImage from '../UploadImage'

export default function EditAccountInitial({ userData }) {

    const [error, seterror] = useState("")

    const { currentUser } = useAuth()

    return (
        <>
            {userData &&
                <div className="new-account account-activation">
                    <UploadImage></UploadImage>
                    <h1>{currentUser.data.firstname + " " + currentUser.data.lastname}</h1>
                    <div className="form-wraper">
                        <div className="new-account">
                            {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}

                            <span className="form-heading">
                                <b>E-mail: </b> {userData.email && userData.email}
                            </span>

                            <span className="form-heading">
                                <b>Tel. číslo: </b> {userData.tel && userData.tel}
                            </span>

                            <Motorcycles motorcycles={userData.motorcycles} uid={userData.userId}></Motorcycles>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
