import { motion } from 'framer-motion'
import React from 'react'

export default function AnimatedRoute({ children }) {

    const variants = {
        initial: {
            opacity: 0,

        },
        animate: {
            opacity: 1,

        },
        exit: {
            opacity: 0,
        },
    }

    return (

        <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            className='central-wrap'
        >
            {children}
        </motion.div>
    )
}
