import React from 'react'

export default function Footer() {
    return (
        <footer className='footer'>
            <div className="footer-wraper">
                <span>Go-ride 2022 | Created by Vancoweb</span>
            </div>
        </footer>
    )
}
