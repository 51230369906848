import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { AnimatePresence, motion } from 'framer-motion'
import { collection, endAt, getDocs, getFirestore, limit, orderBy, query, startAt } from 'firebase/firestore'

export default function SearchForEvent() {

    const [displaySearch, setdisplaySearch] = useState()
    const [searchError, setsearchError] = useState()
    const [searchResult, setsearchResult] = useState()
    const navigate = useNavigate()

    function HandleSelect(item) {
        navigate(`/detail-eventu/${item.eventId}`)
    }

    function HandleSearch(e) {
        const db = getFirestore()
        const poi = e.target.value
        const collectionRef = collection(db, 'events_grid')

        if (poi.length >= 3) {
            const q = query(collectionRef, orderBy('title', 'asc'), startAt(poi), endAt(`${poi}\uf8ff`), limit(3))
            getDocs(q).then(snap => {
                if (!snap.empty) {
                    setsearchError('')
                    var x = []
                    snap.forEach(item => {
                        var poi = item.data()
                        poi.eventId = item.id
                        x.push(poi)
                    })


                    setsearchResult(x)
                    setdisplaySearch(true)

                } else {
                    setdisplaySearch(true)
                    setsearchResult([])
                    setsearchError("Používateľ sa nenašiel")
                }
            })

        } else {
            setsearchResult([])
            setdisplaySearch(false)
        }
    }

    return (
        <div className='find-event'>
            <input className="form-input" autoComplete='off' type="text" id="pretekar" placeholder='Hľadať...' onChange={HandleSearch} />
            <AnimatePresence>
                {displaySearch &&

                    <motion.div
                        initial={{ opacity: 0, y: -100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        className="select-item-dropdown"

                    >
                        <div className="wrap">
                            {searchError && searchError}
                            {searchResult.map((item, i) => {
                                return (
                                    <div key={i + 1} onClick={() => { HandleSelect(item) }} className={`event-item-wrap`}>
                                        <div className="event-item">
                                            <div className="event-name">{item.title}</div>
                                            <span className="event-date">{new Date(item.date).toLocaleDateString()}</span>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}
