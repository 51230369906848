import React from 'react';
import { Route, Routes } from 'react-router';
import CreateAccount from '../components/CreateAccount';
import AfterPaymentScreen from '../screens/AfterPaymentScreen';
import CheckoutScreen from '../screens/CheckoutScreen';
import ContactScreen from '../screens/ContactScreen';
import EventDetailsScreen from '../screens/EventDetailsScreen';
import EventsScreen from '../screens/EventsScreen';
import HomeScreen from '../screens/HomeScreen';
import PageNotFount from '../screens/PageNotFount';
import EditAccountScreen from '../screens/EditAccountScreen';
import { useAuth } from '../contexts/AuthContext';
import Header from '../components/Header';
import InitializeAccount from '../screens/InitializeAccount';
import MyRegistrationsScreen from '../screens/MyRegistrationsScreen';
import LiveDataScreen from '../screens/LiveDataScreen';
import InvitationSuccesfullScreen from '../screens/InvitationSuccesfullScreen';
import InvitationErrorScreen from '../screens/InvitationErrorScreen';
import OrganizersScreen from '../screens/OrganizersScreen';
import Footer from '../components/Footer';


export default function CustomRouter() {

    const { currentUser } = useAuth()

    if (currentUser && currentUser.data.status !== "active") {

        return (<InitializeAccount />)
    }

    return (
        <>
            <Header></Header>
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/kontakt" element={<ContactScreen />} />
                <Route path="/zoznam-eventov" element={<EventsScreen />} />
                {/*<Route path="/zoznam-organizacii" element={<OrganizersScreen />} />*/}
                <Route path="/novy-ucet" element={<CreateAccount />} />
                <Route path="/upravit-ucet" element={<EditAccountScreen />} />
                <Route path="/detail-eventu/:eventName" element={<EventDetailsScreen />} />
                <Route path="/detail-eventu/:eventName/live" element={<LiveDataScreen />} />
                <Route path="/404" element={<PageNotFount />} />
                <Route path="*" element={<PageNotFount />} />

                <Route path="/checkout" element={<CheckoutScreen />} />
                <Route path="/after-payment" element={<AfterPaymentScreen />} />
                <Route path="/moje-registracie" element={<MyRegistrationsScreen />} />

                <Route path="/invitation/success" element={<InvitationSuccesfullScreen />} />
                <Route path="/invitation/error" element={<InvitationErrorScreen />} />
            </Routes>
            <Footer></Footer>
        </>
    );

}
