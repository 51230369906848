import React from 'react'

export default function Notification({ i, message, sender, time }) {
    return (
        <div className='notification' key={i}>
            <div className="message">{message}</div>
            <div className='message-meta'>
                <div className="sender">{sender.userName}</div>
                <div className="time">{new Date(time).getHours()}:{new Date(time).getSeconds() < 10 && '0'}{new Date(time).getSeconds()}</div>
            </div>
        </div>
    )
}
