
export function FirebaseValidation(error) {
    if (error === "auth/invalid-email") return "Emailová adresa nieje správna"
    if (error === "auth/user-disabled") return "Používateľ zablokovaný"
    if (error === "auth/user-not-found") return "Používateľ sa nenašiel"
    if (error === "auth/wrong-password") return "Nesprávne heslo"
    if (error === "auth/too-many-requests") return "Príliš veľa nesprávnych pokusov o prihlásenie"
    if (error === "auth/email-already-in-use") return "Tento email sa už používa"
    if (error === "auth/weak-password") return "Heslo je príliš slabé (minimálne 6 znakov)"

    return error

}

export function ValidateNewAccount(email, password, repeatpassword) {


    if (!email) return "Email je povinné vyplniť"
    if (!email.includes("@") || !email.includes(".")) return "Email je v nesprávnom tvare"
    if (!password) return "Heslo je povinné vyplniť"
    if (!repeatpassword) return "Je povinné overiť heslo"
    if (password !== repeatpassword) return "Heslá sa nezhodujú"

    return
}


export function ValidateAccountUpdate(d, m, y, model, cubature) {
    if (!model) return "Je povinné zadať model motocyklu"
    if (!cubature) return "Je povinné zadať kumatúru"

    if (isNaN(cubature)) return "Neplatný tvar kubatúry"

}



export function ValidateDate(d, m, y) {

    // Sú zadané hodnoty?
    if (!d) return "Deň je povinné vyplniť"
    if (!m) return "Mesiac je povinné vyplniť"
    if (!y) return "Rok je povinné vyplniť"

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    //Prestupný rok
    if (y % 400 === 0 || (y % 100 !== 0 && y % 4 === 0))
        monthLength[1] = 29;


    // je to číslo?

    if (isNaN(d)) return "Deň \"" + d + "\" neexistuje"
    if (isNaN(m)) return "mesiac \"" + m + "\" neexistuje"
    if (isNaN(y)) return "rok \"" + y + "\" neexistuje"

    const currentDate = {
        d: new Date().getDay(),
        m: new Date().getMonth(),
        y: new Date().getFullYear(),
    }

    if (d <= 0 || d > monthLength[m - 1]) return "Dátum \"" + d + "." + m + "." + y + "\" neexistuje"
    if (y < 1000 || y > currentDate.y || y < currentDate.y - 100 || m === 0 || m > 12) return "Neplatný dátum \"" + d + "." + m + "." + y + "\""

    //Overenie vytvoren=im objedku
    const x = Date.parse(y + "-" + m + "-" + d)
    if (x === "Nan") return "Neznáma chyba, kontaktujte podporu (dátum)"

    return
}


export function ValidatePayment(meno, email,) {
    if (!email.includes("@") || !email.includes(".")) return "Email je v nesprávnom tvare"
    return
}
