import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import errorIcon from "../../images/error_icon-red.svg"
import { doc, getFirestore, updateDoc } from '@firebase/firestore';
import { useToast } from '../../contexts/ToastContext';

export default function Motorcycles({ motorcycles, uid }) {

    const variants = {
        initial: { opacity: 0, y: 100 },
        animate: (i) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * .05
            }
        }),
    }


    const modelRef = useRef()
    const cubatureRef = useRef()
    const displayToast = useToast()
    const updateModelRef = useRef()
    const updateCubatureRef = useRef()

    const [addMotorcycle, setaddMotorcycle] = useState(false)
    const [error, seterror] = useState()
    const [loading, setloading] = useState(true)
    const [edited, setedited] = useState(false)
    const [motorcyclesData, setmotorcyclesData] = useState([])
    const [editMotorcycle, seteditMotorcycle] = useState(-1)
    const [UpdateError, setUpdateError] = useState()

    function HandleEdit() {
        setedited(true)
    }

    async function addDataToFirestore(data, uid) {
        const db = getFirestore()


        await updateDoc(doc(db, "users", uid), { "motorcycles": [...data] }).then((snap) => {
            return "success"

        }).catch(err => {
            return err
        })
    }

    function HandleUpdateData(id) {
        setUpdateError("")

        if (!updateModelRef.current.value) {
            setUpdateError("Model je povinné zadať")
            setloading(false)
            return
        }

        if (!updateCubatureRef.current.value) {
            setUpdateError("Je povinné zadať kubatúru")
            setloading(false)
            return
        }

        const model = updateModelRef.current.value;
        const cubature = updateCubatureRef.current.value;

        if (isNaN(cubature)) {
            setUpdateError("\"" + cubature + "\" nieje číslo")
            setloading(false)
            return
        }

        if (!UpdateError) {
            var data = motorcyclesData
            data[id].cubature = cubature
            data[id].model = model


            const add = addDataToFirestore(data, uid);

            if (add) {
                setmotorcyclesData(data)
                seteditMotorcycle(-1)
                displayToast("Uložené", "success")

            } else {

            }

        }
    }

    function SetEditingRow(id) {
        seteditMotorcycle(id)
        setUpdateError("")
    }


    function CancelEdit() {
        if (window.confirm("Naozaj zrušiť bez uloženia?")) {
            seteditMotorcycle(-1)
        }
    }

    function HandleDelete(i) {

        if (window.confirm("Naozaj chcete vymazať?")) {
            var poi = motorcyclesData
            poi.splice(i, 1)
            const add = addDataToFirestore(poi, uid)

            if (add) {
                seterror("")
                setloading(false)
                setmotorcyclesData(poi)
                displayToast("Úspešne vymazané", "success")
            }
            else {

            }
        }
    }

    function HandleSubmit(e) {
        e.preventDefault()
        setloading(true)
        seterror("")


        if (edited) {

            if (!modelRef.current.value) {
                seterror("Model je povinné zadať")
                setloading(false)
                return
            }

            if (!cubatureRef.current.value) {
                seterror("Je povinné zadať kubatúru")
                setloading(false)
                return
            }

            const model = modelRef.current.value;
            const cubature = cubatureRef.current.value;

            if (isNaN(cubature)) {
                seterror("\"" + cubature + "\" nieje číslo")
                setloading(false)
                return
            }

            if (!error) {
                var poi = motorcyclesData
                poi.push({ "cubature": cubature, "model": model })

                const add = addDataToFirestore(poi, uid)

                if (add) {
                    setmotorcyclesData(poi)
                    seterror("")
                    setaddMotorcycle(false)
                    displayToast("Uložené", "success")
                }
                else {

                }
            }
            setloading(false)
            return
        }
    }

    useEffect(() => {
        if (motorcycles) {
            setmotorcyclesData(motorcycles)
            setloading(false)
        }

    }, [])


    return (
        <>
            <h2 className="form-heading">Motorky</h2>
            <div className="motocykel">

                <div className="motorcycle-table">
                    {motorcyclesData[0] && <div className="table-row headline">
                        <div className="model"></div>
                        <div className="cubature">Model</div>
                        <div className="id">Kubatúra</div>
                    </div>}

                    {motorcyclesData.map((motorcycle, i) => {

                        if (i === editMotorcycle) {
                            return (
                                <motion.div
                                    variants={variants}
                                    custom={i}
                                    key={i}
                                >
                                    {UpdateError && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{UpdateError}</div></div>}
                                    <motion.div
                                        className="table-row-wrap" key={i}
                                    >
                                        <div className="table-row editing">

                                            <div className="id"><b>{i + 1}</b></div>
                                            <input className="form-input model" defaultValue={motorcycle.model} ref={updateModelRef} placeholder="Model motocyklu" type="text" id="rok" />
                                            <div className="editable-with-unit cubature"><input className="form-input" ref={updateCubatureRef} defaultValue={motorcycle.cubature} placeholder="Model motocyklu" type="text" id="rok" />cm3</div>


                                            <div className="actions" >
                                                <span onClick={() => { HandleUpdateData(i) }}>Uložiť</span>

                                                <div className="cancel" onClick={() => { CancelEdit() }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                                        <g id="deleteIcon" transform="translate(-1 -1)">
                                                            <circle id="Ellipse_4" data-name="Ellipse 4" cx="10" cy="10" r="10" transform="translate(2 2)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                                            <line id="Line_4" data-name="Line 4" x1="6" y2="6" transform="translate(9 9)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                                            <line id="Line_5" data-name="Line 5" x2="6" y2="6" transform="translate(9 9)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>

                                        </div>
                                    </motion.div>
                                </motion.div>
                            )

                        } else {

                            return (
                                <motion.div className="table-row-wrap" key={i}>
                                    <div className="table-row">
                                        <div className="id"><b>{i + 1}</b></div>
                                        <div className="model">{motorcycle.model}</div>
                                        <div className="cubature">{motorcycle.cubature} cm3</div>


                                        <div className="actions">
                                            <div className="edit" onClick={() => { SetEditingRow(i) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.061 21.828">
                                                    <path id="editIcon" d="M17,3a2.828,2.828,0,0,1,4,4L7.5,20.5,2,22l1.5-5.5Z" transform="translate(-1 -1.172)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                                </svg>

                                            </div>
                                            <div className="delete" onClick={() => { HandleDelete(i, motorcycle.id) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
                                                    <g id="deleteIcon" transform="translate(-1 -1)">
                                                        <circle id="Ellipse_4" data-name="Ellipse 4" cx="10" cy="10" r="10" transform="translate(2 2)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                                        <line id="Line_4" data-name="Line 4" x1="6" y2="6" transform="translate(9 9)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                                        <line id="Line_5" data-name="Line 5" x2="6" y2="6" transform="translate(9 9)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </motion.div>
                            )

                        }
                    })}
                </div>

                {!addMotorcycle && <AnimatePresence><motion.button
                    className="button-primary"
                    onClick={(e) => { e.preventDefault(); setaddMotorcycle(true) }}
                    whileTap={{ scale: 0.90 }}
                >
                    Pridať
                </motion.button></AnimatePresence>}

                {error && <div className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></div>}
                {addMotorcycle &&
                    <AnimatePresence>

                        <motion.div
                            className="add-motorcycle"

                        >
                            <input className="box-shadow form-input" ref={modelRef} placeholder="Model motocyklu" onInput={HandleEdit} type="text" id="model" autoComplete='off' />
                            <input className="box-shadow form-input" ref={cubatureRef} placeholder="Kubatúra" onInput={HandleEdit} type="text" id="cubature" autoComplete='off' />
                            <motion.button
                                className="button-primary button-login"
                                onClick={HandleSubmit}
                                whileTap={{ scale: 0.95 }}
                                disabled={!edited}
                            >
                                Uložiť
                            </motion.button>

                        </motion.div>
                    </AnimatePresence>
                }

            </div>
        </>
    )
}
