import { motion } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions"

export default function CancelRegistrationPopup({ close, rider, event }) {

    const reasonRef = useRef();
    const [error, seterror] = useState("");
    const [loading, setloading] = useState(false);

    const animation = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.2
            }
        },

        show: {
            opacity: 1,
            transition: {
                duration: 0.2
            }
        }
    }


    function HandleDisqualification() {

        seterror("")
        const reason = reasonRef.current.value


        if (!reason) {
            seterror("Musíte uviesť dôvod zrušenia")
            return
        }

        const functions = getFunctions()
        const setRiderStatus = httpsCallable(functions, "setRiderStatus")

        setloading(true)

        setRiderStatus({
            eventId: rider.eventId,
            registrationId: rider.registrationId,
            reason: reason,
            status: "cancelled"
        }).then((x) => {

            setloading(false)
            window.location.reload()

        }).catch((err) => {

            setloading(false)
        })

    }

    return (
        <motion.div
            className='disqualify-popup cancl-registration'
            variants={animation}
            initial={"hidden"}
            animate={"show"}
            exit={"hidden"}
        >
            <div className='wrap'>

                <h3>Zrušiť registráciu</h3>

                <input className="box-shadow form-input" ref={reasonRef} autoComplete='off' type="text" placeholder='Dôvod zrušenia registrácie' id="oneLapTime" />
                <span className='form-validation-error'>{error && error}</span>
                <div className="buttons-wrap">
                    <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.92 }} onClick={() => { HandleDisqualification() }} disabled={loading} className="button-primary">{!loading ? "Zrušiť registráciu" : "Načítavam..."}</motion.button>
                    <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.92 }} onClick={() => { close() }} disabled={loading} className="button-default">Zrušiť</motion.button>
                </div>
            </div>
        </motion.div>
    )
}
