import React, { useEffect, useState } from 'react'
import raceLocationIcon from "../images/race_location_icon.svg"

import { useNavigate } from 'react-router'


export default function EventSliderCard({ data }) {


    const navigate = useNavigate()
    const [difficulty, setdifficulty] = useState()
    const x = [0, 0, 0, 0, 0]

    useEffect(() => {

        setdifficulty(data.difficulty)
    }, [])


    return (
        <div className="card-wrap carousel" onClick={() => { navigate(`/detail-eventu/${data.docId}`) }}>
            <div className="card-body-wraper">
                <div className="card-body">
                    <div className="col1">
                        <div className="row1">
                            <h2>{data.title.length >= 20 ?
                                `${data.title.substring(0, 20)}...`
                                :
                                data.title
                            }</h2>

                            <h3 className="event-date">{data.date}</h3>
                        </div>
                    </div>
                    <div className="col2">
                        <img className='avatar' src={`${data.organizer.avatar !== undefined ? data.organizer.avatar : "https://firebasestorage.googleapis.com/v0/b/bikes-planet.appspot.com/o/avatar%2Fdefault_icon.svg?alt=media&token=d0ac3d3c-b196-4eca-a320-4897ff7dc3f8"}`} alt="avatar" />
                    </div>
                    {<div className='description'>{data.details.description && data.details.description.replace(/<[^>]*>/g, '').substring(0, 120)} ...</div>}
                </div>
                <div className='card-footer'>

                    <div className='difficulty'>
                        {x.map((item, i) => {
                            return (
                                <div key={i}>
                                    {i < difficulty ?
                                        <svg className="dash red" xmlns="http://www.w3.org/2000/svg" width="25" height="5" viewBox="0 0 25 5">
                                            <rect id="dificulty_line" width="20" height="3" rx="2.5" />
                                        </svg>
                                        :
                                        <svg className="dash" xmlns="http://www.w3.org/2000/svg" width="25" height="5" viewBox="0 0 25 5">
                                            <rect id="dificulty_line" width="20" height="3" rx="2.5" />
                                        </svg>}
                                </div>
                            )
                        })}
                    </div>


                </div>
            </div>
        </div>
    )
}

