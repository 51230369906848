import React, { useRef, useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { motion } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router'
import { getFunctions, httpsCallable } from "firebase/functions";
import poweredByStripe from "../images/Stripe_Logo,_revised_2016.svg"
import errorIcon from "../images/error_icon.svg"
import { addDoc, collection, doc, getFirestore, updateDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import AnimatedRoute from '../routes/AnimatedRoute';
import LoadingButton from '../components/LoadingButton';

export default function CheckoutScreen() {
    const stripe = useStripe()
    const elements = useElements()

    const { currentUser } = useAuth()
    const navigate = useNavigate()

    const [processing, setprocessing] = useState(false)
    const [error, seterror] = useState("")
    const [cardFieldValidation, setcardFieldValidation] = useState({ empty: true })


    const nameRef = useRef()
    const emailRef = useRef()
    const adressRef = useRef()
    const cityRef = useRef()
    const zipRef = useRef()

    const { state } = useLocation()
    if (!state) { return (<div>Session Expired</div>) }
    const { registration, eventName, eventAmount } = state





    async function payment() {

        seterror("")

        if (!nameRef.current.value) {
            seterror("Nieje zadané meno")
            return
        }

        if (!emailRef.current.value) {
            seterror("Nieje zadaný email")
            return
        }

        if (!emailRef.current.value.includes("@") || !emailRef.current.value.includes(".")) {
            seterror("Email je v nesprávnom tvare")
            return
        }

        if (!adressRef.current.value) {
            seterror("Nieje zadaná adresa")
            return
        }

        if (!cityRef.current.value) {
            seterror("Nieje zadané mesto")
            return
        }

        if (!zipRef.current.value) {
            seterror("Nieje zadané psč")
            return
        }

        if (cardFieldValidation.empty === true) {

            seterror("Niesu zadaná platobné údaje")
            return
        }

        if (cardFieldValidation.complete === false) {

            seterror("Platobné údaje niesu úplné")
            return
        }

        if (cardFieldValidation.error) {

            seterror(cardFieldValidation.error.message)
            return
        }

        const billingDetaills = {
            name: nameRef.current.value,
            email: emailRef.current.value,
            address: {
                city: cityRef.current.value,
                line1: adressRef.current.value,
                state: "Slovakia",
                postal_code: zipRef.current.value
            }
        }

        setprocessing(true)

        const functions = getFunctions();
        const createStripePayment = httpsCallable(functions, 'createStripePayment');

        const client_secret = await createStripePayment({ amount: eventAmount * 100, eventName: eventName })


        if (!client_secret.data) {
            seterror(`Platba neúspešná: NO_DATA`)
            return
        }

        const CardElem = elements.getElement(CardElement)

        const paymentMethodReq = await stripe.createPaymentMethod({
            type: "card",
            card: CardElem,
            billing_details: billingDetaills,
        })

        if (paymentMethodReq.error) {
            seterror(`Platba neúspešná: ${paymentMethodReq.error.message}`)
            return
        }

        const confirmCardPayment = await stripe.confirmCardPayment(client_secret.data, {
            payment_method: paymentMethodReq.paymentMethod.id,
        })

        if (confirmCardPayment.paymentIntent.status === "succeeded") {
            const db = getFirestore()
            const docRef = collection(db, `/events_grid/${registration.eventId}/registrations/`)
            const userRef = doc(db, `/users/${registration.rider.riderId}/`)

            registration.paymentReceived = true
            registration.paymentDetails = {
                id: confirmCardPayment.paymentIntent.id,
                amount: confirmCardPayment.paymentIntent.amount,
                date: confirmCardPayment.paymentIntent.created
            }

            addDoc(docRef, registration).then((snap) => {
                if (currentUser.data.registered_for) {
                    !currentUser.data.registered_for.includes(registration.eventId) && currentUser.data.registered_for.push(registration.eventId)
                }
                else {
                    currentUser.data.registered_for = [registration.eventId]
                }

                updateDoc(userRef, { "registered_for": currentUser.data.registered_for })
                return

            }).catch((err) => {

            })
        } else {
            seterror(`Platba neúspešná: ${confirmCardPayment.error.message}`)
        }

        setprocessing(false)
        navigate("/after-payment", { replace: true })

    }

    const cardElementOptions = {
        hidePostalCode: true
    }


    return (
        <AnimatedRoute>
            <div className='main-component-wraper'>

                <div className='checkout-page main-component'>
                    <div className='sum'>
                        <h1>Zhrnutie</h1>
                        <h3><b>Názov preteku: </b>{eventName}</h3>

                        <h3>Motorka:</h3>
                        <div><b>Model: </b>{registration.motorcycle.model}</div>
                        <div><b>Kubatatúra: </b>{registration.motorcycle.cubature} cm3</div>

                        <h3>Platba:</h3>
                        <table>
                            <tr>
                                <td><b>Cena za registráciu:</b></td>
                                <td>{(eventAmount - eventAmount * 0.2).toFixed(2)}€ <span className='no-dph'>bez DPH</span></td>
                            </tr>
                            <tr>
                                <td><b>DPH (20%):</b></td>
                                <td>{(eventAmount * 0.2).toFixed(2)}€</td>
                            </tr>
                        </table>

                        <h2>Cena spolu: {eventAmount}€</h2>

                    </div>

                    <div className='fill-data'>
                        <div className="title">Osobné údaje</div>
                        {error && <motion.div initial={{ opacity: 0, y: -50 }} animate={{ y: 0, opacity: 1 }} className="error"><img src={errorIcon} alt="error" /><div className="error-message">{error}</div></motion.div>}
                        <input className="box-shadow form-input" key={1} ref={nameRef} placeholder="Meno" type="text" id="name" defaultValue={registration.rider.name} />
                        <input className="box-shadow form-input" key={2} ref={emailRef} placeholder="Email" type="email" id="email" defaultValue={registration.rider.email} />
                        <input className="box-shadow form-input" key={3} ref={adressRef} placeholder="Adresa" type="text" id="address" />
                        <input className="box-shadow form-input" key={4} ref={cityRef} placeholder="Mesto" type="text" id="city" />
                        <input className="box-shadow form-input" key={5} ref={zipRef} placeholder="PSČ" type="text" id="zip" />

                        <div className='card-form'>
                            <CardElement onChange={(e) => { setcardFieldValidation(e) }} options={cardElementOptions}></CardElement>
                        </div>
                        <div className="stripe-testing-notice">
                            Pre účely testovania použite č. karty 4242 4242 4242 4242, ľubovoľnú dobu expirácie a ľubovoľné CVC.
                        </div>
                        <LoadingButton textLoading={"Spracovávam platbu"} text={`Zaplatiť ${eventAmount} €`} loading={processing} onClick={() => { payment() }} ></LoadingButton>

                        <div className='powered-by-stripe'><span>Powered by</span><img src={poweredByStripe} alt="stripe" /></div>
                    </div>
                </div>
            </div>
        </AnimatedRoute>
    )
}
